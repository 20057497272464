import React, { Component } from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import uploadFeature from './uploadFeature';
import TokenAuth from './components/tokenLogin/index';
import LoginPage from './components/loginPage';
import ProtocolEdit from './components/protocols/edit';
import {
  ProtocolList,
  ProtocolCreate
} from './components/protocols';
import SequenceEdit from './components/sequences/edit'
import {
  SequenceList,
  SequenceCreate
} from './components/sequences';
import {
  FileEdit,
  FileCreate
} from './components/files';
import FileList from './components/files/list';
import {
  UserList,
  UserEdit,
  UserCreate
} from './components/users';
import {
  CustomerList,
  CustomerEdit,
  CustomerCreate
} from './components/customers';
import {
  TooltipListCT,
  TooltipEditCT,
  TooltipCreateCT
} from './components/tooltipsCT';
import {
  TooltipListMRI,
  TooltipEditMRI,
  TooltipCreateMRI
} from './components/tooltipsMRI';
import {
  TooltipOptionListMRI,
  TooltipOptionEditMRI,
  TooltipOptionCreateMRI
} from './components/tooltipsMRIOptions';
import {
  ImageSettingsList,
  ImageSettingsEdit,
  ImageSettingsCreate
} from './components/imageSettings';
import {
  ImagingCoilList,
  ImagingCoilEdit,
  ImagingCoilCreate
} from './components/imagingCoil';
import {
  UserLogsList,
} from './components/userLogs';

import CustomLayout from './components/layout';

import './app.css';

const uploadProvider = uploadFeature(dataProvider);

class App extends Component {

  render() {

    return (
      <Admin
        loginPage={LoginPage}
        customRoutes={[
          <Route
            path="/token-login/:token"
            component={TokenAuth}
          />
        ]}
        dataProvider={uploadProvider}
        authProvider={authProvider}
        appLayout={CustomLayout}
      >
        <Resource
          name="user"
          edit={UserEdit}
          list={UserList}
          create={UserCreate}
        />
        <Resource
          name="customer"
          options={{ label: 'Organizations' }}
          edit={CustomerEdit}
          list={CustomerList}
          create={CustomerCreate}
        />
        <Resource
          name="protocol"
          edit={ProtocolEdit}
          list={ProtocolList}
          create={ProtocolCreate}
        />
        <Resource
          name="sequence"
          list={SequenceList}
          edit={SequenceEdit}
          create={SequenceCreate}
        />
        <Resource
          name="admin-files/images-list/:id"
          options={{ label: 'Images' }}
          list={FileList}
          create={FileCreate}
          edit={FileEdit}
        />
        <Resource
          name="admin-files/sounds-list/:id"
          options={{ label: 'Sounds' }}
          list={FileList}
          create={FileCreate}
          edit={FileEdit}
        />
        <Resource
          name="tooltip/ct"
          options={{ label: 'Tooltips CT' }}
          list={TooltipListCT}
          create={TooltipCreateCT}
          edit={TooltipEditCT}
        />
        <Resource
          name="tooltip/mri"
          options={{ label: 'Tooltips MRI' }}
          list={TooltipListMRI}
          create={TooltipCreateMRI}
          edit={TooltipEditMRI}
        />
        <Resource
          name="options-tooltip"
          options={{ label: 'Tooltips Imaging Option MRI' }}
          list={TooltipOptionListMRI}
          create={TooltipOptionCreateMRI}
          edit={TooltipOptionEditMRI}
        />
        <Resource
          name="image-settings/images"
          options={{ label: 'Background Image' }}
          list={ImageSettingsList}
          create={ImageSettingsCreate}
          edit={ImageSettingsEdit}
        />
        <Resource
          name="coils"
          options={{ label: 'Imaging Coils' }}
          list={ImagingCoilList}
          create={ImagingCoilCreate}
          edit={ImagingCoilEdit}
        />
        <Resource
          name="user-log"
          options={{ label: 'User logs' }}
          list={UserLogsList}
        />
      </Admin>
    );
  }
}

export default App;
