import BaseApiService from './base';

export class TooltipsApiService extends BaseApiService {

  getCTTooltips() {
    return this.request({
      method: 'GET',
      url: '/api-v2/tooltip/available-keys?app=ct',
    });
  }
  
  getCTTooltipsSet() {
    return this.request({
      method: 'GET',
      url: '/api-v2/tooltip/ct',
    });
  }
  
  getMRITooltipsSet() {
    return this.request({
      method: 'GET',
      url: '/api-v2/tooltip/mri',
    });
  }
  
  getMRIOptionTooltipsSet() {
    return this.request({
      method: 'GET',
      url: '/api-v2/options-tooltip',
    });
  }

  getMRITooltips() {
    return this.request({
      method: 'GET',
      url: '/api-v2/tooltip/available-keys?app=mri',
    });
  }
  
  getMRIOptionTooltips() {
    return this.request({
      method: 'GET',
      url: '/api-v2/tooltip/available-keys?app=option',
    });
  }
}
