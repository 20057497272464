import BaseApiService from './base';

export class UserLogsApiService extends BaseApiService {

  deleteAll () {
    return this.request({
      method: 'delete',
      url: '/api-v2/user-log/delete',
    });
  }
}
