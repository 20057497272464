import BaseApiService from './base';

export class UsersApiService extends BaseApiService {

  getAll() {
    return this.request({
      method: 'GET',
      url: '/api-v2/user',
    });
  }
}
