import React, { Component } from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  ImageField
} from 'react-admin';
import CustomToolbar from '../customToolbar';

export class ImagingCoilEdit extends Component {

  render() {
    const File = ({ record, ...rest }) => {
        return  <ImageField source="path" title="Image" record={record} {...rest} />
    }

    return (
      <Edit {...this.props} title={'Edit Background Image'} >
        <SimpleForm validate={this.validateCreation} toolbar={<CustomToolbar showDelBtn={true} />} >
          <TextInput source="name" />
          <File />
        </SimpleForm>
      </Edit>
    );
  }
}

