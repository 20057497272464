import React, { Component } from 'react';
import {
  Edit,
  DisabledInput,
  SimpleForm,
  TextInput,
  ImageField
} from 'react-admin';
import CustomToolbar from '../customToolbar';

export class FileEdit extends Component {

  state = {
    sequenceId: 0
  }

  validateCreation(values) {
      const errors = {};
      if (!values.sequenceId) {
        errors.sequenceId = ['The Sequence Number is required'];
      }
      if (!values.position) {
        errors.position = ['The Position is required'];
      }

      if(values.position && !values.currentPosition) {
        values.currentPosition = values.position;
      }
      
      return errors
  };

  componentDidMount() {
    const pathValues = this.props.location.pathname.split('/');
    this.setState({
      sequenceId:  parseInt(pathValues[pathValues.length - 1]),
    })
  }

  render() {
    const File = ({ record, ...rest }) => {
      if(this.props.basePath.includes('images'))  
        return  <ImageField source="path" title="Image" record={record} {...rest} />

      return <audio controls>
                  <source src={record.path} type={record.type} />
              </audio>;
    }

    return (
      <Edit {...this.props} title={this.props.basePath.includes('images') ? 'Edit Image' : 'Edit Sound'} >
        <SimpleForm 
          validate={this.validateCreation} 
          redirect={`/sequence/${this.state.sequenceId}`} 
          toolbar={<CustomToolbar showDelBtn={true} />} 
        >
          <DisabledInput source="id" />
          <TextInput source="sequenceId" style={{ display: 'none' }} />
          <TextInput source="currentPosition" style={{ display: 'none' }} />
          <TextInput source="position" required />
          <TextInput source="uploadIdentifier" style={{ display: 'none' }} defaultValue="uploadIdentifier" />
          <File />
        </SimpleForm>
      </Edit>
    );
  }
}

