import { TooltipsApiService } from './tooltips';
import { BackgroundImageApiService } from './background';
import { UsersApiService } from './users';
import { UserLogsApiService } from './userLogs';
import { ImagesApiService } from './images';
import { ProtocolsApiService } from './protocol';
import { CoilsApiService } from './coils';
import { SettingsApiService } from './settings';
import { CustomersApiService } from './customer';
import { SequencesApiService } from './sequence';
import { FilesuencesApiService } from './files';

class ApiService {
  constructor() {
    this.tooltips = new TooltipsApiService();
    this.background = new BackgroundImageApiService();
    this.user = new UsersApiService();
    this.userLogs = new UserLogsApiService();
    this.images = new ImagesApiService();
    this.protocols = new ProtocolsApiService();
    this.coils = new CoilsApiService();
    this.settings = new SettingsApiService();
    this.customers = new CustomersApiService();
    this.sequence = new SequencesApiService();
    this.files = new FilesuencesApiService();
  }
}

export const Api = new ApiService();
