import React from 'react';
import { List, Datagrid, TextField, downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = tooltips => {
  const csv = convertToCSV({
    data: tooltips
  });

  downloadCSV(csv, 'optionTooltips');
}

const TooltipOptionListMRI = props => {

  return (
    <List {...props} exporter={exporter}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="title" />
      </Datagrid>
    </List>
  );
};

export { TooltipOptionListMRI };
