const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

const convertSingleFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

const allowedPages = [
    "admin-files/images",
    "admin-files/sounds",
    "admin-files/images-list",
    "admin-files/sounds-list",
    "image-settings/images",
    "coils"
]

const addUploadFeature = requestHandler => (type, resource, params) => {
    let isPageAllowed = false;
    allowedPages.forEach(el => {
        if(resource.includes(el))
            isPageAllowed = true;
    })
    if (type === 'CREATE' && isPageAllowed) {
        if (!params.data.file.rawFile) {
            return Promise.all(params.data.file.sort((el1, el2) => el1.title.localeCompare(el2.title, undefined, { numeric: true, sensitivity: 'base' })).map(convertFileToBase64))
                .then(base64Pictures => base64Pictures.map((picture64, index) => 
                ({
                    src: picture64,
                    title: `${params.data.file[index].rawFile.name}`,
                    mimetype: params.data.file[index].rawFile.type
                })))
                .then(async transformedNewPictures => {
                    for (const el of transformedNewPictures) {
                        await requestHandler(type, resource, {
                            ...params,
                            data: {
                                ...params.data,
                                file: el,
                            },
                        });
                    }

                    return {data: {id: 0}}
                });
        } else {
            const newPictures = [params.data.file.rawFile];

            return Promise.all(newPictures.map(convertSingleFileToBase64))
                .then(base64Pictures => base64Pictures.map((picture64, index) => ({
                    src: picture64,
                    title: `${newPictures[index].name}`,
                    mimetype: newPictures[index].type
                })))
                .then(transformedNewPictures => requestHandler(type, resource, {
                        ...params,
                        data: {
                            ...params.data,
                            file: transformedNewPictures[0],
                        },
                    })
                );
        }
    }

    return requestHandler(type, resource, params);
};

export default addUploadFeature;