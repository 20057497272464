import React, { Component } from 'react';
import { List, Datagrid, TextField, ImageField, downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

export class ImageSettingsList extends Component {  

  exporter = files => {
    files.map(file => {
      delete file.sequence;

      return file;
    })
    const csv = convertToCSV({
      data: files
    });

    const title = this.props.basePath.includes('images') ? 'images_list' : 'sounds_list';
    downloadCSV(csv, title);
  }

  render() {
    const FileField = ({ record, ...rest }) => {
      return  <ImageField source="path" title="Image" record={record} {...rest} />
    };

    return (
      <List 
        {...this.props} 
        title='Login page images' 
        exporter={false}>
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="appType" />
          <FileField/>
        </Datagrid>
      </List>
    );
  }
}