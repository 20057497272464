import BaseApiService from './base';

export class ImagesApiService extends BaseApiService {

  getImages(id) {
    return this.request({
      method: 'GET',
      url: `/api-v2/admin-files/sequence/${id}`,
    });
  }

  reorder(id, data) {
    return this.request({
      method: 'PUT',
      url: `/api-v2/admin-files/reorder/${id}`,
      data
    });
  }
}
