import React from 'react';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
import UploadCSV from '../utils/uploadCSV';
import { Button, Menu } from '@material-ui/core';

import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';

import './style.css';

class PostActions extends React.PureComponent {

  state = {
    anchorEl: null,
    anchorElImp: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClickImp = event => {
    this.setState({ anchorElImp: event.currentTarget });
  };

  handleCloseImp = () => {
    this.setState({ anchorElImp: null });
  };

  render() {

    const {
      bulkActions,
      basePath,
      currentSort,
      displayedFilters,
      exporter,
      filters,
      filterValues,
      onUnselectItems,
      resource,
      selectedIds,
      showFilter,
      total,
      endpointСT,
      endpointMRI,
      history,
      sequenceId,
      goBack,
      backBtn,
      filetype,
      ct,
      mri
    } = this.props;

    return (
      <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
        {backBtn && <Button
          className="cancel-btn-action"
          onClick={() => goBack()}
        >
          Back
        </Button>}
        {sequenceId ? <Button className="custom-create" onClick={() => history.push(`/admin-files/${filetype}/create/${sequenceId}`)}><span class="plus">+</span>Create</Button> :
          <CreateButton basePath={basePath} />}

        <div>
          <Button
            aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true"
            color="primary"
            onClick={this.handleClick}
          >
            Open Export Menu
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
          >
            {ct && <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filter={filterValues}
              label="Export CT"
              exporter={el => exporter(el, 'ct', this)}
            />}
            {mri && <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filter={filterValues}
              label="Export MRI"
              exporter={el => exporter(el, 'mri', this)}
            />}
          </Menu>
        </div>

        {/* Add your custom actions */}
        <div>
          <Button
            aria-owns={this.state.anchorElImp ? 'simple-menu' : undefined}
            aria-haspopup="true"
            color="primary"
            onClick={this.handleClickImp}
          >
            Open Import Menu
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={this.state.anchorElImp}
            open={Boolean(this.state.anchorElImp)}
            onClose={this.handleCloseImp}
          >
            {ct && <UploadCSV accept=".csv" label="Upload CT" custom endpoint={endpointСT} ct />}
            {mri && <UploadCSV accept=".csv" label="Upload MRI" custom endpoint={endpointMRI} mri />}
          </Menu>
        </div>
      </CardActions>
    )
  }
}

export default connect(null, {
  goBack
})(PostActions);