import React, { Component } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  LongTextInput,
  DisabledInput,
  BooleanInput,
  NumberInput,
  SelectArrayInput
} from 'react-admin';
import CustomToolbar from '../customToolbar';
import { config } from '../../configs/config';

export class CustomerEdit extends Component {

  state = {
    isDisabled: false
  }

  validateCreation = values => {
    const errors = {};
    if (!values.name) {
      errors.name = ['The Name is required'];
    }
    if (!values.seats) {
      errors.seats = ['The Seats is required'];
    }

    if (values.unlimitedAccess) {
      this.setState({ isDisabled: true })
    }

    return errors
  };

  render() {
    return (
      <Edit {...this.props} title="Edit Organization">
        <SimpleForm
          validate={this.validateCreation}
          toolbar={<CustomToolbar
            modal={true}
            api="user"
            showDelBtn={true}
            endOfMessage=" users. Deleting this organization will delete the users. This action cannot be undone. Do you wish to proceed?"
          />}
        >
          <DisabledInput source="id" />
          <TextInput source="name" required />
          <LongTextInput source="description" />
          <TextInput source="seats" required />
          <NumberInput source="deactivateInterval" disabled={this.state.isDisabled} />
          <SelectArrayInput
            label="Allowed Apps"
            source="appCode"
            choices={config.allowedApps}
          />
          <BooleanInput
            label="Unlimited Access"
            source="unlimitedAccess"
            onClick={e => this.setState({ isDisabled: e.target.checked })} />
          <BooleanInput label="Active" source="active" />
        </SimpleForm>
      </Edit>
    );
  }
}