import BaseApiService from './base';

export class FilesuencesApiService extends BaseApiService {

    getAllBySequenceID(id) {
        return this.request({
            method: 'GET',
            url: `/api-v2/admin-files/sequence/${id}`,
        });
    }

    update(id, data) {
        return this.request({
            method: 'PUT',
            url: `/api-v2/admin-files/${id}`,
            data
        });
    }
}
