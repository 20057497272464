import React, { Component } from 'react';
import { 
    TextInput,
    Filter,
    ReferenceInput,
    SelectInput
  } from 'react-admin';
import { Api } from '../../services';

import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ActionDelete from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { refreshView } from 'react-admin';

class SettingsPanel extends Component {

    state = {
        selectedDateStart: '',
        selectedDateEnd: '',
        showDialog: false
    }

    componentDidMount() {
        const date = new Date();

        this.setState({
            selectedDateStart: `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`,
            selectedDateEnd: `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
        })
    }

    handleCloseClick = () => {
        this.setState({
            showDialog: false
        })
    }

    handleDelete = async () => {
        await Api.userLogs.deleteAll();
        this.props.refreshView();
        this.handleCloseClick();
    }

    handlePrint = () => {
        const element = document.getElementById('datagrid');
        const print = document.getElementById('printFrame').contentWindow;
        const head = document.querySelector('head').innerHTML;
        print.document.open();
        print.document.write(head);
        print.document.write(element.parentElement.innerHTML);
        print.document.close();
        print.focus();
        print.print()
    }

    render() {
        return (
            <React.Fragment>
                <Filter {...this.props}>
                    <TextInput
                        type="date" 
                        source="startDate"
                        defaultValue={ this.state.selectedDateStart }
                        alwaysOn
                    />
                    <TextInput
                        type="date" 
                        source="endDate"
                        defaultValue={ this.state.selectedDateEnd }
                        alwaysOn
                    />
                    <ReferenceInput source="userId" reference="user" alwaysOn perPage={10000000}>
                        <SelectInput optionText="username" />
                    </ReferenceInput>            
                    <Button 
                        variant="contained"
                        onClick={() => this.setState({
                            showDialog: true
                        })} alwaysOn
                    >Clear History</Button> 
                    <Button 
                        variant="contained"
                        onClick={this.handlePrint} alwaysOn
                    >Print</Button> 
                </Filter>
                <Dialog fullWidth open={this.state.showDialog} onClose={this.handleCloseClick} aria-label="Are you sure?">
                    <DialogTitle>Are you sure you want to delete logs history?</DialogTitle>
                        <DialogContent>
                            <div>
                                All logs will be deleted.
                            </div>
                        </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDelete} label="" key="button">
                            <ActionDelete />
                        </Button>
                        <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
                <iframe id="printFrame" style={{display: 'none'}} title="print"></iframe>
            </React.Fragment>
        );
    }
}

SettingsPanel.propTypes = {
    refreshView: PropTypes.func,
};

export default connect(null, {
    refreshView
})(SettingsPanel);