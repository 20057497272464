import React, { Component } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  LongTextInput,
  BooleanInput,
  NumberInput,
  SelectArrayInput
} from 'react-admin';
import CustomToolbar from '../customToolbar';
import { config } from '../../configs/config';

export class CustomerCreate extends Component {

  state = {
    isDisabled: false
  }

  validateCreation(values) {
      const errors = {};
      if (!values.name) {
          errors.name = ['The Name is required'];
      }
      if (!values.seats) {
          errors.seats = ['The Seats is required'];
      } 

      return errors
  };

  render() {
    return (
      <Create {...this.props}>
        <SimpleForm 
          validate={this.validateCreation} 
          toolbar={<CustomToolbar />} 
          title="Create Organization"
          redirect={`/customer`}
        >
          <TextInput source="name" required />
          <LongTextInput source="description" />
          <TextInput source="seats" required />
          <NumberInput source="deactivateInterval"  disabled={this.state.isDisabled} />
          <SelectArrayInput
            label="Allowed Apps"
            source="appCode"
            choices={config.allowedApps}
          />
          <BooleanInput 
            label="Unlimited Access" 
            source="unlimitedAccess" 
            defaultValue={false} 
            onClick={e => this.setState({isDisabled: e.target.checked})} />
          <BooleanInput label="Active" source="active" />
        </SimpleForm>
      </Create>
    );
  }
}