import React from 'react';
import { Button } from 'react-admin';
import { Api } from '../../services';
import { connect } from 'react-redux';
import { showNotification, refreshView } from 'react-admin';
import PropTypes from 'prop-types';

import * as DownArrow from './images/downArrow.png';
import * as UpArrow from './images/upArrow.png';
import './style.css';

class RowActions extends React.PureComponent {

    clickHandler = async direction => {
        const { record } = this.props;
        const response = await Api.images.reorder(record.id, {
                mediaType: record.mediaType,
                sequenceId: record.sequenceId,
                direction,
                position: record.position
            })

        if((response.data && !response.data.success) || !response.data) {
            showNotification(`Error appear while changing order`)
        }
        this.props.refreshView();
    }

    render() {      

        return (
            <div className="order-action">
                <Button 
                    title="Up"
                    onClick={() => this.clickHandler('up')}
                >
                    <img src={UpArrow} alt="Up" />
                </Button>
                <Button 
                    title="Down"
                    onClick={() => this.clickHandler('down')}
                >
                    <img src={DownArrow} alt="Down" />
                </Button>
            </div>
        )
    }
}

RowActions.propTypes = {
    showNotification: PropTypes.func
};

export default connect(null, {
    refreshView,
    showNotification
})(RowActions);