import BaseApiService from './base';

export class SettingsApiService extends BaseApiService {

  getDeactivateInterval() {
    return this.request({
        method: 'GET',
        url: `/api-v2/settings/property/deactivate`,
    });
  }

  update(id, interval) {
    return this.request({
        method: 'PUT',
        url: `/api-v2/settings/${id}`,
        data: {
            value: interval
        }
    });
  }
 
  create(interval) {
    return this.request({
        method: 'POST',
        url: `/api-v2/settings`,
        data: {
          property: 'deactivate',
          value: interval
        }
    });
  }
  
  updateAll(data) {
    return this.request({
        method: 'PUT',
        url: `/api-v2/settings/updateAll`,
        data
    });
  }
}
