import React from 'react';
import { Login } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { Api } from '../../services';

const styles = ({
	main: { 
		backgroundImage: 'none !important',
	}
});

class CustomLoginPage extends React.PureComponent {

	state = {
		image: ''
	}

	async componentDidMount() {
		const response = await Api.background.get();
		if(response.data)
			this.setState({
				image: response.data.imagePath
			})
	}
    
	render() {
		return <div style={{ 
			backgroundImage: `url(${this.state.image})`,
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat', 
		}}>
			<Login {...this.props} />
		</div>
	}
}

export default withStyles(styles)(CustomLoginPage);