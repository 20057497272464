import React, { Component } from 'react';
import {
  Create,
  SimpleForm,
  SelectInput,
  BooleanInput,
  TextInput,
  ReferenceInput,
} from 'react-admin';
import { config } from '../../configs/config';
import CustomToolbar from '../customToolbar';
import getInfo from '../utils/getInfo';

class ProtocolCreate extends Component {

  state = {
    role: 0,
    authCustomerId: 0,
    customer: {
      appCode: []
    },
    showSharableBtn: false
  }

  componentDidMount() {
    this.setState({
      role: parseInt(localStorage.getItem('authRole')),
      authCustomerId: parseInt(localStorage.getItem('authCustomerId'))
    })
  }

  validateCreation = async values => {
    const errors = {};

    if (values.customerId !== this.state.customer.id) {
      getInfo(this, values.customerId, 'customers', 'customer');
    }

    if (values.customerId) {
      this.setState({
        showSharableBtn: true
      })
    } else {
      this.setState({
        showSharableBtn: false
      })
    }

    return errors
  };

  render() {
    return (
      <Create {...this.props}>
        <SimpleForm toolbar={<CustomToolbar />} redirect={`/protocol`} validate={this.validateCreation} >
          {
            this.state.role === config.superAdminId ? <ReferenceInput
              label="Organization"
              source="customerId"
              reference="customer"
              allowEmpty={this.state.role === config.superAdminId}
              emptyLabel="None"
              perPage={1000} // count elemements in list
              required
            >
              <SelectInput optionText="name" />
            </ReferenceInput> :
              <TextInput
                source="customerId"
                required
                defaultValue={this.state.authCustomerId}
                style={{ display: 'none' }} />
          }
          <SelectInput
            source="part"
            label="Body Part"
            choices={config.bodyParts}
          />
          <TextInput source="name" />
          <SelectInput
            label="Allowed in Apps"
            source="usedInApps"
            choices={config.allowedApps.filter(el => this.state.customer.appCode.includes(el.id))}
          />
          {this.state.showSharableBtn && <BooleanInput source="isSharable" />}
        </SimpleForm>
      </Create>
    );
  }
}

export { ProtocolCreate };