import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from '@material-ui/core/Button';
import { Modal } from '@material-ui/core';
import { goBack } from 'react-router-redux';
import { withStyles } from '@material-ui/core/styles';
import { Api } from '../../services';
import { SaveButton, Toolbar, DeleteButton } from 'react-admin';

import './style.css';

const styles = theme => ({
    modalRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    text: {
        marginBottom: 40,
        fonеWeight: '500',
        fontFamily: "Roboto, Helvetica, Arial sans-serif",
        lineHeight: '1.4em'
    }
});

class CustomToolbar extends Component {

    state = {
        isDeleteModal: false,
        data: []
    }

    handleClick = () => {
        if (this.props.redirectPath) {
            this.props.history.push(this.props.redirectPath)
        } else {
            this.props.goBack();
        }
    };

    async componentDidMount() {
        if (this.props.showDelBtn && typeof this.props.modal !== 'undefined' && !this.props.justText) {
            if (!this.props.method) {
                const response = await Api[this.props.api].getAll();
                if (response.status === 200) {
                    this.setState({
                        data: response.data.filter(el => el.customerId === this.props.record.id)
                    })
                }
            } else {
                const response = await Api[this.props.api][this.props.method](this.props.id);
                if (response.status === 200) {
                    this.setState({
                        data: response.data
                    })
                }
            }
        }
    }

    delete = () => {
        if (this.props.showDelBtn && typeof this.props.modal !== 'undefined') {
            return (
                <React.Fragment>
                    <Button
                        variant="contained"
                        onClick={() => {
                            this.setState({
                                isDeleteModal: true
                            })
                        }}>Delete</Button>
                    <Modal open={this.state.isDeleteModal} className={this.props.classes.modalRoot}>
                        <div className={this.props.classes.paper}>
                            <div className={this.props.classes.text}>
                                {!this.props.justText ? (<React.Fragment>
                                    <b>Warning</b>, there are <b>{this.state.data.length}</b>
                                </React.Fragment>) : ''}{this.props.endOfMessage}
                            </div>
                            <div>
                                <DeleteButton resource={this.props.resource} {...this.props} />
                                <Button onClick={() => this.setState({ isDeleteModal: false })}>Cancel</Button>
                            </div>
                        </div>
                    </Modal>
                </React.Fragment>
            )
        } else {
            return <DeleteButton resource={this.props.resource} {...this.props} />
        }
    }

    render() {
        let toolbarProps = {}
        let skipProps = ['goBack',
            'endOfMessage',
            'redirectPath',
            'justText',
            'modal',
            'showDelBtn',
            'isShare',
            'share',
            'modalRoot',
            'paper',
            'text'
        ]
        for (let k in this.props) {
            if (!skipProps.includes(k))
                toolbarProps = {
                    ...toolbarProps,
                    [k]: this.props[k]
                }
        }

        return <Toolbar {...toolbarProps} className="toolbar-custom" >
            {this.props.isShare && <Button
                className="shareBtn"
                onClick={this.props.share}>Share</Button>}
            <SaveButton
                className="save-btn"
                basePath={this.props.basePath}
            />
            <Button
                variant="contained"
                className="cancel-btn"
                onClick={this.handleClick}
            >
                Cancel
                    </Button>
            {this.delete()}
        </Toolbar>
    }
}

export default compose(connect(null, {
    goBack
}), withStyles(styles))(CustomToolbar);