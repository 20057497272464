import React from 'react';
import { connect } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { config } from '../../configs/config';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        overflow: 'auto',
        padding: 14,
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
        }
    },
    active: {
        color: 'blue',
        borderBottom: '2px solid #E56180'
    },
    btnRoot: {
        width: '',
        overflow: 'unset'
    }
})

const privatePages = ["customer", "tooltip/ct", "tooltip/mri", "image-settings/images", "options-tooltip", "coils"];
const pagesClosedForAll = ["admin-files/images", "admin-files/sounds", "admin-files/images-list", "admin-files/sounds-list"];

class Menu extends React.Component {

    state = {
        role: 0
    }

    componentDidMount() {
        this.setState({
            role: parseInt(localStorage.getItem('authRole'))
        })
    }

    render() {
        return (
            <div className={this.props.classes.root}>
                {this.props.resources.map((resource, k) => {
                    let display = this.state.role === config.superAdminId || !privatePages.includes(resource.name)

                    if (display) {
                        pagesClosedForAll.forEach(el => {
                            if (resource.name.includes(el))
                                display = false;
                        })
                    }

                    if (display)
                        return (
                            <MenuItemLink
                                key={`menu-${k}`}
                                to={`/${resource.name}`}
                                primaryText={resource.options.label ? resource.options.label : (resource.name.charAt(0).toUpperCase() + resource.name.slice(1))}
                                onClick={this.props.onMenuClick}
                                classes={{
                                    active: this.props.classes.active,
                                    root: this.props.classes.btnRoot
                                }}
                            />
                        )
                    else
                        return null
                })}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Menu)));