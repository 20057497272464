import React, { Component } from 'react';
import {
  Edit,
  DisabledInput,
  SimpleForm,
  RadioButtonGroupInput,
  ImageField
} from 'react-admin';
import CustomToolbar from '../customToolbar';
import { config } from '../../configs/config';

export class ImageSettingsEdit extends Component {

  render() {
    const File = ({ record, ...rest }) => {
        return  <ImageField source="path" title="Image" record={record} {...rest} />
    }

    return (
      <Edit {...this.props} title={'Edit Background Image'} >
        <SimpleForm validate={this.validateCreation} toolbar={<CustomToolbar showDelBtn={true} />} >
          <DisabledInput source="id" />
          <RadioButtonGroupInput source="appType" choices={config.imagesAppTypes} />
          <File />
        </SimpleForm>
      </Edit>
    );
  }
}

