import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  downloadCSV,
  Filter,
  TextInput,
  ReferenceField,
  SelectInput,
  BooleanField
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import PostActions from '../customListActions';
import { config } from '../../configs/config';

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <SelectInput
      source="part"
      label="Body Part"
      choices={config.bodyParts}
      alwaysOn
    />
  </Filter>
);

const ShareIcon = props => {
  props.record.isSharable = props.record.isSharable ? true : false;
  return <BooleanField {...props} source="isSharable" label="Shared" />
}

class ProtocolList extends React.PureComponent {
  state = {
    role: 0,
  }

  componentDidMount() {
    this.setState({
      role: parseInt(localStorage.getItem('authRole')),
    })
  }

  exporter = protocols => {
    protocols.map(protocol => {
      if (this.state.role !== config.superAdminId)
        delete protocol.customerId;

      delete protocol.source;
      delete protocol.hasReconstructionSequence;
      delete protocol.sequences;

      protocol.usedInApps = protocol.usedInApps.join('&');

      return protocol;
    })

    const csv = convertToCSV({
      data: protocols
    });

    downloadCSV(csv, 'protocols');
  }

  render() {
    return (
      <List
        {...this.props}
        bulkActions={false}
        exporter={this.exporter}
        filters={<PostFilter />}
        actions={<PostActions showUploadCSV endpoint="protocol" />}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="name" />
          <ShareIcon label="Shareable" />
          {this.state.role === config.superAdminId &&
            <ReferenceField source="customerId" reference="customer" label="Organization" allowEmpty={true}>
              <TextField source="name" />
            </ReferenceField>
          }
        </Datagrid>
      </List>
    );
  }
};

export { ProtocolList };
