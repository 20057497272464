import React, { Component } from 'react';
import { 
    List, 
    Datagrid, 
    TextField,
} from 'react-admin';
import SettingsPanel from './settingsPanel';

export class UserLogsList extends Component {

    render() {
        return (
            <React.Fragment>
                <List 
                    {...this.props } 
                    filters={<SettingsPanel />} 
                    bulkActions={false}
                >
                    <Datagrid id="datagrid">
                        <TextField source="userId" />
                        <TextField source="loginAt" label="Login" />
                        <TextField source="logoutAt" label="Logout" />
                        <TextField source="duration" label="Total time" />
                        <TextField source="protocols" />
                        <TextField source="appType" label="Application" />
                    </Datagrid>
                </List>
            </React.Fragment>
        );
    }
}