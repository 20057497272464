import { Api } from '../../services';

export default async (_this, id, service, state) => {
    if(parseInt(id) > 0) {
        const response = await Api[service].getById(id);
        if(response.status === 200)
            _this.setState({
                [state]: response.data
            })
    }
}