import React, { Component } from 'react';
import { List, Datagrid, TextField, downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import PostActions from '../customListActions';

export class CustomerList extends Component {
  
  exporter = organizations => {
    const organizationsForExport = organizations.map( organization => {
      if(!organization.unlimitedAccess)
        organization.unlimitedAccess = 0;

      organization.appCode = organization.appCode.join('&');

      return organization;
    }) 

    const csv = convertToCSV({
      data: organizationsForExport,
      fields: ['id', 'name', 'description','seats','deactivateInterval','unlimitedAccess','active','appCode','createdAt','updatedAt']
    });

    downloadCSV(csv, 'organizations');
  }

  render() {
    return (
      <React.Fragment>
        <List 
          {...this.props} 
          bulkActions={false} 
          exporter={this.exporter} 
          actions={<PostActions showUploadCSV endpoint="customer" />}>
          <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="seats" />
            <TextField source="active" />
          </Datagrid>
        </List>
      </React.Fragment>
    );
  }
}