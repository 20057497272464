import BaseApiService from './base';

export class ProtocolsApiService extends BaseApiService {

  getAll() {
    return this.request({
      method: 'GET',
      url: '/api-v2/protocol',
    });
  }

  getByPartId(id) {
    return this.request({
      method: 'GET',
      url: `/api-v2/protocol/part/${id}`,
    });
  }

  share(id) {
    return this.request({
      method: 'POST',
      url: `/api-v2/protocol/share/${id}`,
    });
  }
}
