import BaseApiService from './base';

export class SequencesApiService extends BaseApiService {

    getAllByProtocolID(id) {
        return this.request({
            method: 'GET',
            url: `/api-v2/sequence/protocol/${id}/all`,
        });
    }

    share(id) {
        return this.request({
            method: 'POST',
            url: `/api-v2/sequence/share/${id}`,
        });
    }

    getById(id) {
        return this.request({
            method: 'GET',
            url: `/api-v2/sequence/${id}`,
        });
    }
}
