import BaseApiService from './base';

export class CustomersApiService extends BaseApiService {

  getById(id) {
    return this.request({
      method: 'GET',
      url: `/api-v2/customer/${id}`,
    });
  }

  getAll() {
    return this.request({
      method: 'GET',
      url: `/api-v2/customer?order.by=name`
    });
  }
}
