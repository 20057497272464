import React from 'react';
import PropTypes from 'prop-types';
import { 
    withStyles,
    TextField,
    Button
} from '@material-ui/core';
import { Api } from '../../../services';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { refreshView } from 'react-admin';

const styles = theme => ({
    root: {
        marginBottom: '10px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    interval: {
        width: '40px',
        margin: '0 10px'
    },
    btnNew: {
        margin: '0 10px',
        fontSize: '0.8125rem',
        padding: '7px 8px',
        minWidth: '64px',
        minHeight: '32px',
    },
    btnAll: {
        margin: '0 10px',
        fontSize: '0.8125rem',
        padding: '7px 8px',
        minWidth: '64px',
        minHeight: '32px',
    },
});

class DeactivateSettings extends React.PureComponent {

    state = {
        interval: 0,
        id: 0 
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    getDefaultInterval = async() => {
        const response = await Api.settings.getDeactivateInterval();
        if(response.status === 200) {
            this.setState({
                interval: isNaN(parseInt(response.data.value)) ? 0 : parseInt(response.data.value),
                id: response.data.id
            })
        }
    }
    
    updateDefaultInterval = async () => {
        if(typeof this.state.id !== "undefined") {
            await Api.settings.update(this.state.id, this.state.interval);
        } else {
            await Api.settings.create(this.state.interval);
        }      
    }
    
    updateAllUsersInterval = async () => {
        await Api.settings.updateAll({ deactivateInterval: this.state.interval });
        this.props.refreshView()
    }

    componentDidMount() {
        this.getDefaultInterval();
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <label>Automatically deactivate account after</label>
                <TextField
                    className={classes.interval}
                    value={this.state.interval}
                    onChange={this.handleChange('interval')}
                />
                <label>days (default settings)</label>
                <Button 
                    className={classes.btnNew}
                    onClick={() => this.updateDefaultInterval()}
                    variant="contained">Apply to new users</Button>
                <Button 
                    className={classes.btnAll}
                    onClick={() => this.updateAllUsersInterval()}
                    variant="contained">Apply to all users</Button>
            </div>
            
        )
    }
}

DeactivateSettings.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(connect(null, {
    refreshView
}), withStyles(styles))(DeactivateSettings);