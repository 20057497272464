import { config } from '../../../configs/config';

export const inputsCT = [
  {
    label: 'Series Name',
    field: 'seriesDesc',
    inputType: 'text',
    required: true,
    defaultValue: 'Scout'
  },
  {
    field: 'serialNumber',
    inputType: 'number',
    required: true,
    defaultValue: 5,
  },
  {
    field: 'type',
    inputType: 'select',
    choices: config.sequences.type,
    required: true,
    defaultValue: 'Scannable'
  },
  {
    label: 'Scan Type',
    field: 'scanType',
    inputType: 'select',
    choices: config.scanType,
    defaultValue: '1'
  },
  // {
  //   field: 'seriesDesc',
  //   inputType: 'text',
  // },
  {
    label: 'Patient Position',
    field: 'patientPosition',
    inputType: 'select',
    choices: config.patientPositions,
    defaultValue: 1
  },
  {
    label: 'Patient Entry',
    field: 'patientEntry',
    inputType: 'select',
    choices: config.patientEntries,
    defaultValue: 1
  },
  {
    label: 'Imaging Plane',
    field: 'imagingPlane',
    inputType: 'select',
    choices: config.imageingPlane,
    defaultValue: '2'
  },
  {
    field: 'sliceThickness',
    inputType: 'text',
  },
  {
    field: 'sliceGap',
    inputType: 'text',
  },
  {
    field: 'fieldOfView',
    inputType: 'number',
    defaultValue: 50,
    min: 24,
    max: 70,
    required: true
  },
  {
    field: 'gantryTilt',
    inputType: 'text',
  },
  {
    label: 'kV',
    field: 'kv',
    inputType: 'select',
    choices: config.kV,
    defaultValue: 130
  },
  {
    label: 'mA',
    field: 'ma',
    inputType: 'text',
    defaultValue: 25,
  },
  {
    field: 'exposureTime',
    inputType: 'text',
    defaultValue: 3.4
  },
  {
    field: 'smartPrep',
    inputType: 'text',
    defaultValue: 'No'
  },
  {
    field: 'pitch',
    inputType: 'text',
    defaultValue: 1,
  },
  {
    field: 'detectorArray',
    inputType: 'select',
    choices: config.detectorArray,
    defaultValue: 8
  },
  {
    field: 'breathHold',
    inputType: 'text',
    defaultValue: 4,
  },
  {
    field: 'collimation',
    inputType: 'text',
  },
  {
    field: 'imageMatrix',
    inputType: 'text',
    defaultValue: '512 x 512'
  },
  {
    label: 'Reconstruction Algorithm',
    field: 'reconstructionAlgorithm',
    inputType: 'select',
    choices: config.reconstructionAlgorithm,
  },
  {
    field: 'startLocation',
    inputType: 'text',
    defaultValue: 'S150'
  },
  {
    field: 'endLocation',
    inputType: 'text',
    defaultValue: 'S350'
  },
  {
    field: 'contrastAmount',
    inputType: 'text',
  },
  {
    field: 'contrastAgent',
    inputType: 'text',
    defaultValue: 'N/A'
  },
  {
    label: 'Window/Label',
    field: 'windowLevel',
    inputType: 'text',
    defaultValue: '500/50'
  },
  {
    label: 'Filter',
    field: 'filter',
    inputType: 'select',
    choices: config.seqFilter,
  },
  {
    field: 'phase',
    inputType: 'number',
    step: 1,
    min: 1,
    max: 2,
  },
  {
    label: 'side A',
    field: 'sideA',
    inputType: 'checkbox',
  },
  {
    label: 'side B',
    field: 'sideB',
    inputType: 'checkbox',
  },
  {
    label: 'Flow ml/sec',
    field: 'sflowMlSecideB',
    inputType: 'number',
    step: 1,
    min: 1,
  },
  {
    field: 'volumeMl',
    inputType: 'number',
    step: 1,
    min: 1,
  },
  {
    field: 'duration',
    inputType: 'number',
    step: 1,
    min: 1,
  },
  {
    field: 'totalTime',
    inputType: 'text',
  },
  {
    label: 'injection 1 delay',
    field: 'injectionDelay',
    inputType: 'number',
    step: 1,
    min: 1,
  },
  {
    label: 'injection 2 delay',
    field: 'injectionDelay2',
    inputType: 'number',
    step: 1,
    min: 1,
  },
  {
    field: 'scanDelay',
    inputType: 'number',
    step: 1,
    min: 1,
  },
  {
    label: 'Peak PSI',
    field: 'peakPSI',
    inputType: 'number',
    step: 1,
    min: 1,
    defaultValue: 90,
  },
  {
    field: 'speed',
    inputType: 'text',
    defaultValue: 1,
  },
  {
    field: 'noOfImages',
    inputType: 'text',
    defaultValue: 1,
  },
  {
    label: 'Images',
    field: 'imagesField',
    inputType: 'text',
  },
]