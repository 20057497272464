import React from 'react'; 
import Menu from '../mainMenu';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
    AppBar,
    Notification,
    setSidebarVisibility,
} from 'react-admin';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        minHeight: '100vh',
        position: 'relative', 
    },
    appFrame: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 2,
        padding: theme.spacing.unit * 3,
        marginTop: '2em',
    },
    sidebar: {
        width: 'auto'
    },
    menu: {
        display: 'flex',
        flexDirection: 'row',
    },
    appBar: {
        backgroundColor: 'blue'
    },
    iconButton: {
        display: 'none'
    },
    title: {
        display: 'none'
    },
    toolbar: {
        justifyContent: 'flex-end'
    }
});

class CustomLayout extends React.Component {
    componentDidMount() {
        this.props.setSidebarVisibility(true);
    }

    render() {
        const {
            children,
            classes,
            logout,
            open,
            title,
        } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.appFrame}>
                    <AppBar title={title} open={open} logout={logout} className={classes.appBar} classes={{
                        menuButton: classes.iconButton,
                        title: classes.title,
                        toolbar: classes.toolbar,
                    }} />
                    <main>
                        <Menu />
                        <div className={classes.content}>
                            {children}
                        </div>
                    </main>
                    <Notification />
                </div>
            </div>
        );
    }
}

CustomLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    dashboard: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
    ]),
    isLoading: PropTypes.bool.isRequired,
    setSidebarVisibility: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });
export default connect(mapStateToProps, { setSidebarVisibility })(withStyles(styles)(CustomLayout));