import React, { Component } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  downloadCSV
} from 'react-admin';
import { Select, MenuItem } from '@material-ui/core';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import RowActions from './rowActions';
import PostActions from '../customListActions';
import { config } from '../../configs/config';
import { Api } from '../../services';
import { connect } from 'react-redux';

class PlaneType extends Component {
  state = {
    value: '1',
    fileData: {}
  };

  componentDidMount() {
    if (!this.state.fileData.id)
      this.setState({
        fileData: this.props.record,
        value: this.props.record.planeType
      });
  }

  handleChange = e => {
    Api.files
      .update(this.state.fileData.id, {
        ...this.state.fileData,
        planeType: e.target.value
      })
      .then(() => {
        this.setState({
          value: e.target.value
        });
      });
  };

  render() {
    //MRI planning options
    if (
      this.props.record.sequence &&
      this.props.record.sequence.plane === MULTIPLANE
    ) {
      return (
        <Select value={this.state.value} onChange={this.handleChange}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {config.imageingPlane.map((el, k) => (
            <MenuItem key={k} value={el.id}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
      );
    }

    //CT planning options
    if (
      this.props.record.sequence &&
      this.props.record.sequence.imagingPlane === MULTIPLANE
    ) {
      return (
        //make colonal if its multiplane on default
        <Select
          value={this.state.value === '4' ? '1' : this.state.value}
          onChange={this.handleChange}
        >
          {config.imageingPlaneCT.map((el, k) => (
            <MenuItem key={k} value={el.id}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
      );
    }
    return '';
  }
}

const FileField = ({ record, ...rest }) => {
  if (record.mediaType === 'image')
    return <ImageField source="path" title="Image" record={record} {...rest} />;

  return null;
};

const MULTIPLANE = '4';
const CustomDataGrid = props => {
  let data = {};
  for (let item in props.data) {
    if (
      props.data[item].sequenceId ===
      parseInt(parseInt(localStorage.getItem('sequenceId')))
    ) {
      data = {
        ...data,
        [item]: props.data[item]
      };
    }
  }
  const newProps = {
    ...props,
    data
  };

  if (Object.values(data).length === 0) delete newProps.data;

  return (
    <Datagrid {...newProps}>
      <TextField source="id" />
      <TextField source="mediaType" />
      <TextField source="name" />
      <TextField source="position" />
      <FileField />
      {props.basePath.includes('images') && <PlaneType />}
      {Object.values(data).length !== 0 && <RowActions />}
    </Datagrid>
  );
};
export class FileList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sequenceId: parseInt(localStorage.getItem('sequenceId')),
      seriesDesc: localStorage.getItem('seriesDesc')
    };
  }

  exporter = files => {
    files.map(file => {
      delete file.sequence;

      return file;
    });
    const csv = convertToCSV({
      data: files
    });

    const title = this.props.basePath.includes('images')
      ? 'images_list'
      : 'sounds_list';
    downloadCSV(csv, title);
  };

  render() {
    return (
      <div>
        <div className="description">
          {this.state.seriesDesc ? String(this.state.seriesDesc) : ''}
        </div>
        <List
          {...this.props}
          data={[]}
          title={
            this.props.basePath.includes('images')
              ? 'Images List'
              : 'Sounds List'
          }
          exporter={this.exporter}
          filter={{ sequenceId: this.state.sequenceId }}
          sort={{ field: 'position', order: 'ASC' }}
          actions={
            <PostActions
              endpoint="sequence"
              filetype={
                this.props.basePath.includes('images')
                  ? 'images-list'
                  : 'sounds-list'
              }
              history={this.props.history}
              sequenceId={this.state.sequenceId}
              backBtn
              backPath={`/sequence/${this.state.sequenceId}`}
            />
          }
        >
          <CustomDataGrid />
        </List>
      </div>
    );
  }
}

export default connect(
  state => ({ state }),
  reducers => ({ reducers })
)(FileList);
