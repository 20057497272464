export const config = {
  server: process.env.REACT_APP_API_URL,
  superAdminId: 1,
  mri: 'mri',
  ct: 'ct',
  allowedApps: [
    { id: 'ct', name: 'CT' },
    { id: 'mri', name: 'MRI' },
    { id: 'mriWeb', name: 'MRI Web' }
  ],
  sequences: {
    type: [
      { id: 'Reconstruction', name: 'Reconstruction' },
      { id: 'Scannable', name: 'Scannable' }
    ]
  },
  bodyParts: [
    { id: 1, name: 'Head/Brain', value: 1 },
    { id: 2, name: 'Neck/Cervical', value: 2 },
    { id: 3, name: 'Chest/Thoracic', value: 3 },
    { id: 4, name: 'Upper Extremity', value: 4 },
    { id: 5, name: 'Abdomen/Lumbar', value: 5 },
    { id: 6, name: 'Pelvis', value: 6 },
    { id: 7, name: 'Lower Extremity', value: 7 }
  ],
  roles: [
    { id: 1, name: 'Super administrator' },
    { id: 2, name: 'Administrator' },
    { id: 3, name: 'User' }
  ],
  patientEntries: [
    { id: 1, name: 'Head First' },
    { id: 2, name: 'Feet First' }
  ],
  patientPositions: [{ id: 1, name: 'Supine' }, { id: 2, name: 'Prone' }],
  reconstructionAlgorithm: [
    { id: '1', name: 'Soft Tissue' },
    { id: '2', name: 'Bone' }
  ],
  seqFilter: [{ id: '1', name: 'Standard' }, { id: '2', name: 'Sharp' }],
  scanType: [
    { id: '1', name: 'Localizer' },
    { id: '2', name: 'Axial' },
    { id: '3', name: 'Helical' }
  ],
  imageingPlane: [
    { id: '1', name: 'Coronal' },
    { id: '2', name: 'Sagittal' },
    { id: '3', name: 'Axial' },
    { id: '4', name: 'Multiplane' }
  ],
  imageingPlaneCT: [
    { id: '1', name: 'AP' },
    { id: '2', name: 'Lateral' },
    // { id: '3', name: 'Axial' }
  ],
  planeType: [
    { id: '1', name: 'Coronal' },
    { id: '2', name: 'Sagittal' },
    { id: '3', name: 'Axial' },
    { id: '4', name: 'Multiplane' }
  ],
  detectorArray: [
    { id: 2, name: '2' },
    { id: 4, name: '4' },
    { id: 8, name: '8' },
    { id: 16, name: '16' },
    { id: 32, name: '32' },
    { id: 64, name: '64' },
    { id: 128, name: '128' },
    { id: 256, name: '256' }
  ],
  kV: [
    { id: 80, name: '80' },
    { id: 100, name: '100' },
    { id: 110, name: '110' },
    { id: 120, name: '120' },
    { id: 130, name: '130' },
    { id: 140, name: '140' }
  ],
  imagesAppTypes: [
    { id: '', name: 'None' },
    { id: 'ct', name: 'CT' },
    { id: 'mri', name: 'MRI' },
    { id: 'admin', name: 'Admin' }
  ],
  imgOptions: [
    { id: '1', name: 'Cardiac Gating', value: 'Cardiac Gating' },
    { id: '2', name: 'Peripheral Gating', value: 'Peripheral Gating' },
    { id: '3', name: 'Fat Sat', value: 'Fat Sat' },
    { id: '4', name: 'Water Sat', value: 'Water Sat' },
    { id: '5', name: 'No Phase Wrap', value: 'No Phase Wrap' },
    { id: '6', name: 'Rectangular FOV', value: 'Rectangular FOV' },
    { id: '7', name: 'Tailored RF', value: 'Tailored RF' },
    { id: '8', name: 'Flow Comp', value: 'Flow Comp' },
    { id: '9', name: 'Respiratory Comp', value: 'Respiratory Comp' },
    {
      id: '10',
      name: 'Extended Dynamic Range',
      value: 'Extended Dynamic Range'
    },
    { id: '11', name: 'Square Pixel', value: 'Square Pixel' },
    { id: '12', name: 'Select All', value: 'all' }
  ]
};
