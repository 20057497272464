import React, { Component } from 'react';
import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  RadioButtonGroupInput
} from 'react-admin';
import CustomToolbar from '../customToolbar';
import { config } from '../../configs/config';

export class ImageSettingsCreate extends Component {

  render() {
    const UploadFile = ({ record, ...rest }) =>
      <FileInput 
        source="file" 
        label="Related files" 
        accept={'image/*'}>
          <FileField source="src" title="title" />
      </FileInput>

    return (
      <Create {...this.props} title='Create Background Image' >
        <SimpleForm validate={this.validateCreation} redirect={`/image-settings/images`} toolbar={<CustomToolbar />} >
          <UploadFile />
          <RadioButtonGroupInput source="appType" choices={config.imagesAppTypes} />
        </SimpleForm>
      </Create>
    );
  }
}
