import BaseApiService from './base';

export class CoilsApiService extends BaseApiService {

  getCoils() {
    return this.request({
      method: 'GET',
      url: `/api-v2/coils`,
    });
  }
}
