import React, { Component } from 'react';
import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  TextInput
} from 'react-admin';
import CustomToolbar from '../customToolbar';

export class ImagingCoilCreate extends Component {

  render() {
    const UploadFile = ({ record, ...rest }) =>
      <FileInput 
        source="file" 
        label="Related files" 
        accept={'image/*'}>
          <FileField source="src" title="title" />
      </FileInput>

    return (
      <Create {...this.props} title='Create Imaging Coil' >
        <SimpleForm redirect={`/coils`} toolbar={<CustomToolbar />} >
          <TextInput source="name" />
          <UploadFile />
        </SimpleForm>
      </Create>
    );
  }
}
