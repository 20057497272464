import React, { Component } from 'react';
import {
  Edit,
  SimpleForm,
  SelectInput,
  BooleanInput,
  TextInput,
  ReferenceInput,
  showNotification
} from 'react-admin';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { config } from '../../configs/config';
import CustomToolbar from '../customToolbar';
import getInfo from '../utils/getInfo';

import { Api } from '../../services';


const App = props => {

  if (props.record.source) {
    return <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{
        color: 'rgba(0, 0, 0, 0.54)',
        padding: 0,
        fontSize: '0.8rem',
        marginTop: '10px',
      }}>{props.label}</span>
      <span style={{
        marginTop: '10px',
      }}>{props.record.usedInApps.join(' ')}</span>
    </div>
  }
  return <SelectInput
    {...props}
    label="Allowed in Apps*"
    source="usedInApps"
    choices={config.allowedApps}
    style={{ display: 'none' }}
    format={value => Array.isArray(value) ? value : []}
    defaultValue={props.usedInAppsState}
  />
}

const CustomReferenceInput = ({ record, allowEmpty }) => {

  return (
    <ReferenceInput
      label="Organization"
      source="customerId"
      reference="customer"
      allowEmpty={allowEmpty}
      emptyLabel="None"
      perPage={1000} // count elemements in list
      required
      filter={record.source ? { app: record.usedInApps[0] } : {}}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  )
}

class ProtocolEdit extends Component {

  state = {
    role: 0,
    authCustomerId: 0,
    customer: {
      appCode: []
    },
    showSharableBtn: false,
    isSharable: false,
    usedInApps: []
  }

  componentDidMount() {
    this.setState({
      role: parseInt(localStorage.getItem('authRole')),
      authCustomerId: parseInt(localStorage.getItem('authCustomerId'))
    })
  }

  share = async () => {
    const response = await Api.protocols.share(this.props.id);
    if (response.status === 200) {
      this.props.showNotification('Copy Created')
    } else {
      this.props.showNotification(response.data.error)
    }
  }

  validateCreation = values => {
    const errors = {};

    if (values.customerId !== this.state.customer.id) {
      getInfo(this, values.customerId, 'customers', 'customer');
    }

    if (Array.isArray(values.usedInApps)) {
      values.usedInApps = values.usedInApps[0]
    }

    this.setState({ isSharable: values.isSharable ? true : false })

    if (values.customerId) {
      this.setState({
        showSharableBtn: true
      })
    } else {
      this.setState({
        showSharableBtn: false
      })
    }

    return errors;
  };

  render() {
    return (
      <React.Fragment>
        <Edit {...this.props} >
          <SimpleForm toolbar={<CustomToolbar
            isShare={this.state.role === config.superAdminId && this.state.isSharable}
            redirectPath="/protocol"
            history={this.props.history}
            share={this.share}
            showDelBtn={true}
            api="sequence"
            modal={true}
            method="getAllByProtocolID"
            id={this.props.location.pathname.split('/').pop()}
            endOfMessage=" sequence associated with this protocol. Deleting this protocol will delete the sequence as well. This action cannot be undone. Do you wish to proceed?"
          />} validate={this.validateCreation}>
            {
              this.state.role === config.superAdminId ? <CustomReferenceInput
                allowEmpty={this.state.role === config.superAdminId}
              /> :
                <TextInput source="customerId" required defaultValue={this.state.authCustomerId} style={{ display: 'none' }} />
            }
            <SelectInput
              source="part"
              label="Body Part"
              choices={config.bodyParts}
            />
            <TextInput source="name" />
            <App label="Allowed in Apps*" usedInAppsState={config.allowedApps.filter(el => this.state.customer.appCode.includes(el.id))} />
            {this.state.showSharableBtn && <BooleanInput source="isSharable" />}
          </SimpleForm>
        </Edit>
      </React.Fragment>
    );
  }
}

ProtocolEdit.propTypes = {
  showNotification: PropTypes.func
};

export default connect(null, {
  showNotification,
})(ProtocolEdit);
