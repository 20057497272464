import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
import axios from 'axios';
import { config } from './configs/config';

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    return axios({
      method: 'post',
      url: `${config.server}/login`,
      data: params,
      headers: { app: 'admin' }
    })
      .then(response => {
        localStorage.setItem('authToken', response.data.token);
        localStorage.setItem('authRole', response.data.user.role.id);
        localStorage.setItem('authCustomerId', response.data.user.customerId);
        localStorage.setItem('allowed', response.data.user.appCode);
        return Promise.resolve();
      })
      .catch(err => Promise.reject('Invalid username or password'));
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('authToken');
    localStorage.removeItem('authRole');
    localStorage.removeItem('authCustomerId');
    localStorage.removeItem('allowed');
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('authRole');
      localStorage.removeItem('allowed');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    const authToken = localStorage.getItem('authToken');

    if (!authToken) {
      return Promise.reject();
    }

    return axios({
      method: 'get',
      url: `${config.server}/verifyAuth`,
      headers: { Authorization: `Bearer ${authToken}` }
    })
      .then(response => Promise.resolve())
      .catch(err => Promise.reject());
  }
  return Promise.reject('Unknown method');
};
