import React, { Component } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  FileInput,
  FileField,
} from 'react-admin';
import CustomToolbar from '../customToolbar';

export class FileCreate extends Component {

  state = {
    sequenceId: 0,
    sequencePlaneType: 0,
    planeType: 1
  }

  validateCreation = values => {
    const errors = {};
    if (!values.sequenceId) {
      errors.sequenceId = ['The Sequence Number is required'];
    }

    return errors
  };

  componentDidMount() {
    const pathValues = this.props.location.pathname.split('/');
    this.setState({
      sequenceId: parseInt(pathValues[pathValues.length - 2]),
    });
  }

  render() {
    const UploadFile = ({ record, ...rest }) =>
      <FileInput
        multiple
        source="file"
        label="Related files"
        accept={this.props.basePath.includes('images') ? 'image/*' : 'audio/*'}
        >
        <FileField source="src" title="title" />
      </FileInput>

    return (
      <Create {...this.props} title={this.props.basePath.includes('images') ? 'Create Image' : 'Create Sound'} >
        <SimpleForm validate={this.validateCreation} redirect={this.props.basePath.includes('images') ? `/admin-files/images-list/${this.state.sequenceId}` : `/admin-files/sounds-list/${this.state.sequenceId}`} toolbar={<CustomToolbar />} >
          <TextInput source="sequenceId" style={{ display: 'none' }} defaultValue={this.state.sequenceId} />
          <TextInput source="planeType" style={{ display: 'none' }} defaultValue={this.state.planeType} />
          <UploadFile />
          <TextInput source="uploadIdentifier" style={{ display: 'none' }} defaultValue="uploadIdentifier" />
        </SimpleForm>
      </Create>
    );
  }
}
