import React, { Component } from 'react';
import {
  Create,
  SimpleForm,
  SelectInput,
  TextInput,
  LongTextInput
} from 'react-admin';
import { Api } from '../../services';
import CustomToolbar from '../customToolbar';
import { config } from '../../configs/config';

class TooltipCreateMRI extends Component {

  state = {
    tooltipsKeys: []
  };

  getTooptipKeys = async () => {
    const response = await Api.tooltips.getMRITooltips();
    const responseTooltips = await Api.tooltips.getMRITooltipsSet();

    let usedKeys = responseTooltips.data
      .filter(element => (parseInt(this.props.id) !== element.id && element.usedInApps[0] === config.mri))
      .map(obj => obj.elementName);

    let allowedKeys = [];
    if(response.status === 200) {
      allowedKeys = response.data.data.filter(el => !usedKeys.includes(el.id))
    
      allowedKeys.push({id: '', name: 'None'})

      this.setState({
        tooltipsKeys: allowedKeys
      })
    }
  }
  
  componentDidMount() {
    this.getTooptipKeys()
  }

  render() {
    return (
      <Create {...this.props} >
        <SimpleForm toolbar={<CustomToolbar />} redirect={`/tooltip/mri`} >
          <SelectInput
            source="elementName"
            label="Element Name"
            sort={{field: 'name', order: 'ASC'}}
            choices={this.state.tooltipsKeys}
          />
          <LongTextInput source="text" resettable />
          <TextInput source="usedInApps" defaultValue={config.mri} style={{display: 'none'}}/>
        </SimpleForm>
      </Create>
    );
  }
}

export { TooltipCreateMRI };
