import React, { Component } from 'react';
import { Modal, Paper, withStyles } from '@material-ui/core';
import { Api } from '../../services';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showNotification } from 'react-admin';
import * as CloseBtn from '../../images/close.png';
import {
    DisabledInput,
    TextInput,
    Button
} from 'react-admin';

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center'
    },
    paper: {
        width: '50%',
        height: '80%',
        margin: 'auto',
        minHeight: '100px',
        minWidth: '150px',
        overflow: 'auto'
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '15px',
        padding: '5px',
        background: '#0000FF',
        opacity: '0.9'
    },
    closeBtn: {
        width: '20px',
        height: '20px',
        cursor: 'pointer'
    },
    body: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 20,
        height: '100%'
    },
    coils: {
        width: '200px',
        height: '200px',
        margin: '10px',
        cursor: 'pointer',
        '&:hover': {
            opacity: '0.8',
        },
    },
    selected: {
        border: '1px solid blue'
    },
    selectBtn: {
        background: 'whitesmoke',
        border: '1px solid gray',
        margin: '0 0 0 30px',
        minWidth: '30px !important'
    },
    imageCoilsBlock: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 20,
        height: 'min-content'
    },
    imageCoilsText: {

    }
});


class CoilsModal extends Component {

    state = {
        open: false,
        coils: [],
        selectedCoil: {
            name: ''
        },
        realValue: 0
    }

    getCoils = async coilId => {
        const response = await Api.coils.getCoils();
        if (response.status === 200) {
            const selectedCoil = response.data.find(el => el.id === parseInt(coilId))
            this.setState({
                coils: response.data,
                selectedCoil: selectedCoil ? selectedCoil : this.state.selectedCoil,
                realValue: selectedCoil ? selectedCoil.id : 0
            })
            delete this.props.record.imagingCoil
        }
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleOpen = () => {
        this.setState({
            open: true
        })
    }

    handleSelectCoil = coil => {
        this.setState({
            selectedCoil: coil,
            realValue: coil ? coil.id : 0
        });
        this.handleClose();
    }

    componentDidMount() {
        this.getCoils(this.props.record.imagingCoil);
    }

    componentDidUpdate() {
        if (this.props.record.imagingCoil) {
            this.getCoils(this.props.record.imagingCoil);
        }
    }

    render() {
        return (
            <React.Fragment>
                <DisabledInput
                    name=""
                    label={this.props.label}
                    defaultValue={this.state.selectedCoil.name} />
                <Button
                    className={this.props.classes.selectBtn}
                    onClick={this.handleOpen}
                ></Button>
                <TextInput
                    name=""
                    source={this.props.source}
                    defaultValue={this.state.realValue}
                    style={{ display: 'none' }}
                />
                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    className={this.props.classes.root}
                >
                    <Paper className={this.props.classes.paper}>
                        <div className={this.props.classes.header}>
                            <img
                                src={CloseBtn}
                                alt="Close button"
                                onClick={this.handleClose}
                                className={this.props.classes.closeBtn} />
                        </div>
                        <div className={this.props.classes.body}>
                            {
                                this.state.coils.map(el => (
                                    <div className={this.props.classes.imageCoilsBlock} key={`image-coil-div-${el.id}`}>
                                        <img
                                            key={`image-coil-${el.id}`}
                                            src={el.path}
                                            alt={el.name}
                                            onClick={() => this.handleSelectCoil(el)}
                                            className={`${this.props.classes.coils} ${el.id === parseInt(this.state.realValue) ? this.props.classes.selected : ''}`} />
                                        <span className={this.props.classes.imageCoilsText}>{el.name}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </Paper>
                </Modal>
            </React.Fragment>
        );
    }
}

CoilsModal.propTypes = {
    showNotification: PropTypes.func
};

export default (connect(null, {
    showNotification,
}), withStyles(styles))(CoilsModal);