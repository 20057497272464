import React, { Component } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DisabledInput,
  SelectArrayInput,
  SelectInput,
  BooleanInput,
  ReferenceInput
} from 'react-admin';
import { config } from '../../configs/config';
import CustomToolbar from '../customToolbar';
import DeactivateAccount from './components/deactivateAccount';
import getInfo from '../utils/getInfo';

const DEFAULT_MASK_PASS = "defaultValue";

export class UserEdit extends Component {

  state = {
    role: 0,
    authCustomerId: 0,
    customer: {
      appCode: []
    }
  }

  componentDidMount() {
    this.setState({
      role: parseInt(localStorage.getItem('authRole')),
      authCustomerId: parseInt(localStorage.getItem('authCustomerId'))
    })
  }

  validateCreation = values => {
    const errors = {};
    if (!values.customerId) {
      errors.customerId = ['The Organization is required'];
    }
    if (!values.roleId) {
      errors.roleId = ['The Role is required'];
    }
    if (values.appCode.length === 0 || (values.appCode.length === 1 && !values.appCode[0])) {
      errors.appCode = ['The Allowed in Apps field is required'];
    }
    if (!values.firstname) {
      errors.firstname = ['The Firstname is required'];
    }
    if (!values.lastname) {
      errors.lastname = ['The Lastname is required'];
    }
    if (!values.country) {
      errors.country = ['The Country is required'];
    }
    if (!values.state) {
      errors.state = ['The State is required'];
    }
    if (!values.city) {
      errors.city = ['The City is required'];
    }

    if (values.newPassword) {
      if (!values.newPasswordConfirm) {
        errors.newPasswordConfirm = ['Please enter confirm password for changing'];
      }
      if (values.newPassword.length < 8) {
        errors.newPassword = ['Min length 8 characters'];
      }
      if (values.newPasswordConfirm && values.newPasswordConfirm.length < 8) {
        errors.newPasswordConfirm = ['Min length 8 characters'];
      }
      if (values.newPasswordConfirm && (values.newPasswordConfirm !== values.newPassword)) {
        errors.newPasswordConfirm = ['Confirm password and new password should match'];
        errors.newPassword = ['Confirm password and new password should match'];
      }
    }

    if (values.customerId !== this.state.customer.id) {
      getInfo(this, values.customerId, 'customers', 'customer');
    }

    if (Array.isArray(values.appCode)) {
      values.appCode = values.appCode;
    }

    return errors
  };


  render() {
    return (
      <Edit {...this.props} title="Edit User" >
        <SimpleForm validate={this.validateCreation} toolbar={<CustomToolbar showDelBtn={true} />} >
          <DisabledInput source="id" />
          {
            this.state.role === config.superAdminId ? <ReferenceInput
              label="Organization"
              source="customerId"
              reference="customer"
              perPage={1000}
              required
            >
              <SelectInput optionText="name" />
            </ReferenceInput> :
              <TextInput source="customerId" required defaultValue={this.state.authCustomerId} style={{ display: 'none' }} />
          }
          <SelectInput
            source="roleId"
            choices={
              this.state.role === config.superAdminId ? config.roles : config.roles.filter(el => el.id !== config.superAdminId)
            }
            required
          />
          <DisabledInput source="username" />
          <TextInput type="password" source="newPassword" label="Password" defaultValue={DEFAULT_MASK_PASS} />
          <TextInput type="password" source="newPasswordConfirm" label="Confirm password" defaultValue={DEFAULT_MASK_PASS} />
          <DisabledInput source="email" />
          <TextInput source="firstname" required />
          <TextInput source="lastname" required />
          <TextInput source="country" required />
          <TextInput source="state" required />
          <TextInput source="city" required />
          <SelectArrayInput
            label="Allowed in Apps"
            source="appCode"
            required
            choices={config.allowedApps.filter(el => this.state.customer.appCode.includes(el.id))}
          />
          <BooleanInput label="Separate Seat" source="separateSeat" />
          <BooleanInput label="Active" source="active" />
          <DeactivateAccount source="deactivateInterval" />
        </SimpleForm>
      </Edit>
    );
  }
}