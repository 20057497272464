import React, { Component } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectArrayInput,
  SelectInput,
  BooleanInput,
  ReferenceInput
} from 'react-admin';
import { config } from '../../configs/config';
import CustomToolbar from '../customToolbar';
import DeactivateAccount from './components/deactivateAccount';
import getInfo from '../utils/getInfo';

export class UserCreate extends Component {

  state = {
      role: 0,
      authCustomerId: 0,
      customer: {
        appCode: []
      }
  }

  componentDidMount() {
      this.setState({
          role: parseInt(localStorage.getItem('authRole')),
          authCustomerId: parseInt(localStorage.getItem('authCustomerId'))
      })
  }

  validateCreation = values => {
      const errors = {};
      if (!values.customerId) {
          errors.customerId = ['The Customer is required'];
      }
      if (!values.roleId) {
          errors.roleId = ['The Role is required'];
      } 
      if (!values.username) {
          errors.username = ['The Username is required'];
      } 
      if (!values.password) {
          errors.password = ['The Password is required'];
      } 
      if (!values.confirmPassword) {
          errors.confirmPassword = ['The Confirm Password is required'];
      } 

      if(values.password && values.password.length < 8) {
        errors.password = ['Min Password length is 8'];
      }
      if(values.confirmPassword && values.confirmPassword.length < 8) {
        errors.confirmPassword = ['Min Password length is 8'];
      }

      if(values.password && values.confirmPassword && values.confirmPassword !== values.password){
        errors.confirmPassword = ['Passwords should match'];
        errors.password = ['Passwords should match'];
      } 

      if (!values.email) {
          errors.email = ['The Email is required'];
      } 
      if (!values.appCode || 
        (values.appCode && !values.appCode.length) || 
        (values.appCode && values.appCode.length === 1 && !values.appCode[0])) {
          errors.appCode = ['The Allowed in Apps field is required'];
      } 
      if (!values.firstname) {
          errors.firstname = ['The Firstname is required'];
      } 
      if (!values.lastname) {
          errors.lastname = ['The Lastname is required'];
      } 
      if (!values.organization) {
          errors.organization = ['The Organization is required'];
      } 
      if (!values.country) {
          errors.country = ['The Country is required'];
      } 
      if (!values.state) {
          errors.state = ['The State is required'];
      } 
      if (!values.city) {
          errors.city = ['The City is required'];
      } 

      if(values.customerId !== this.state.customer.id) {
        getInfo(this, values.customerId, 'customers', 'customer');
      }

      return errors
  };

  render() {
    return (
      <Create {...this.props} >
        <SimpleForm 
          validate={this.validateCreation} 
          toolbar={<CustomToolbar />} 
          redirect={`/user`} 
        >
          {
            this.state.role === config.superAdminId ? <ReferenceInput
              label="Organization"
              source="customerId"
              reference="customer"
              perPage={1000}
              sort={{field: "name", order: "ASC"}}
              required
            >
              <SelectInput optionText="name" />
            </ReferenceInput> : 
            <TextInput source="customerId" required defaultValue={this.state.authCustomerId} style={{display: 'none'}} />
          }
          <SelectInput 
            source="roleId" 
            choices={
              this.state.role === config.superAdminId ? config.roles : config.roles.filter(el => el.id !== config.superAdminId)
            } 
            required 
          />
          <TextInput source="username" required />
          <TextInput type="password" source="password" required />
          <TextInput type="password" source="confirmPassword" required />
          <TextInput source="email" type="email" required />
          <TextInput source="firstname" required />
          <TextInput source="lastname" required />
          <TextInput source="country" required />
          <TextInput source="state" required />
          <TextInput source="city" required />
          <SelectArrayInput
            label="Allowed in Apps"
            source="appCode"
            required
            choices={config.allowedApps.filter(el => this.state.customer.appCode.includes(el.id))}
          />
          <BooleanInput label="Separate Seat" source="separateSeat" />
          <BooleanInput label="Active" source="active" />
          <DeactivateAccount source="deactivateInterval" />          
        </SimpleForm>
      </Create>
    );
  }
}
