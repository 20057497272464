import axios from 'axios';
import { config } from '../configs/config';

export default class BaseApiService {
  handleResponse = response => {
    return response;
  };

  handleError = error => {
    if (error.response)
      return {
        error: error.response.data.message || error.response.data.error
      };
    else return { error: error };
  };

  request = ({ method, url, params, data }, addAuthToken = true) => {
    const authToken = localStorage.getItem('authToken');
    let headers = {
      app: 'admin'
    };

    if(addAuthToken)
      headers = { 
        ...headers,
        Authorization: `Bearer ${authToken}`
      };

    return axios({
      baseURL: config.server,
      timeout: 30000,
      maxContentLength: 10000,
      method,
      url,
      headers,
      params,
      data
    }).then(this.handleResponse, this.handleError);
  };
}
