import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import { showNotification, refreshView } from 'react-admin';
import { push } from 'react-router-redux';
import './style.css';

class UploadCSV extends Component {

    state = {
        accept: '*/*',
        isMultiple: false,
        endpoint: 'user'
    }

    handleChange = e => {
        e.preventDefault();

        let data = new FormData();
        const { refreshView, showNotification } = this.props;
        console.log(this.props)
        data.append('file', e.target.files[0]);

        const url = this.props.custom ?
            `${process.env.REACT_APP_API_URL}/api-v2/${this.state.endpoint}` :
            `${process.env.REACT_APP_API_URL}/api-v2/${this.state.endpoint}/csv`
        axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${window.localStorage.authToken}`,
                app: 'admin'
            }
        }).then(resp => {
            refreshView();
            showNotification('CSV uploaded')
        }, error => {
            if (!error.response.data.error.code) {
                showNotification(`Error: ${error.response.data.error}`)
            } else {
                showNotification(`Error: ${error.response.data.error.code}`)
            }
            refreshView();
        })
    }

    componentDidMount() {
        this.setState({
            endpoint: this.props.endpoint
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.endpoint !== this.props.endpoint) {
            this.setState({
                endpoint: this.props.endpoint
            })
        }
    }

    render() {
        const { accept } = this.props;

        let id = "contained-button-file";
        if (this.props.ct) {
            id = "contained-button-file-ct";
        }
        if (this.props.mri) {
            id = "contained-button-file-mri";
        }
        return (
            <React.Fragment>
                <input
                    type="file"
                    name="file"
                    id={id}
                    className="csv-upload-input"
                    onChange={this.handleChange}
                    multiple={this.state.isMultiple}
                    onClick={e => e.target.value = null}
                    accept={accept ? accept : this.state.accept}
                />
                <label
                    htmlFor={id}
                >
                    <Button
                        component="span"
                        className="upload-csv-btn">
                        <AttachFile />
                        {this.props.label ? this.props.label : 'Upload CSV'}
                    </Button>
                </label>
            </React.Fragment>
        );
    }
}

UploadCSV.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func
};

export default connect(null, {
    showNotification,
    push,
    refreshView
})(UploadCSV);
