import React from 'react';
import PropTypes from 'prop-types';
import { 
    withStyles,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    TextField
} from '@material-ui/core';
import {
    TextInput,
} from 'react-admin';
import { Api } from '../../../services';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: `${theme.spacing.unit * 3}px 0`,
    },
    group: {
        margin: `0 ${theme.spacing.unit * 6}px`,
    },
    customeAction: {
        position: 'absolute',
        left: '200px',
        top: '73px',
        width: '40px',
    }
});

class DeactivateAccount extends React.PureComponent {

    state = {
        value: 'default',
        customInterval: '',
        unlimitedAccess: false,
        defaultInterval: 0,
    };

    handleChange = name => event => {
        if((name === 'customInterval' && this.state.value === 'custom') || name !== 'customInterval' )
            this.setState({ [name]: event.target.value });
    };

    async componentDidMount() {
        const { record: { deactivateInterval, unlimitedAccess } } = this.props;
        await this.getDefaultInterval();

        let state = 'default';
        if(parseInt(deactivateInterval) === -1 || deactivateInterval === 'Never') {
            state = 'never';           
        } else if(parseInt(deactivateInterval) !== this.state.defaultInterval && typeof deactivateInterval !== "undefined") {
            state = 'custom'
        }

        this.setState({
            value: state,
            customInterval: typeof deactivateInterval === "undefined" ? this.state.defaultInterval : deactivateInterval,
            unlimitedAccess
        })

        delete this.props.record.deactivateInterval;
        delete this.props.record.unlimitedAccess;
    }

    getDefaultInterval = async() => {
        const response = await Api.settings.getDeactivateInterval();
        if(response.status === 200) {
            this.setState({
                defaultInterval: isNaN(parseInt(response.data.value))? 0 : parseInt(response.data.value)
            })
        }
    }

    componentDidUpdate() {
        if(this.props.record.deactivateInterval) {
            this.setState({
                customInterval: this.props.record.deactivateInterval,
                unlimitedAccess: this.props.record.unlimitedAccess
            })

            delete this.props.record.deactivateInterval;
            delete this.props.record.unlimitedAccess;
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel>Deactivate account in:</FormLabel>
                    <RadioGroup
                        aria-label="gender"
                        name="gender2"
                        className={classes.group}
                        value={this.state.value}
                        onChange={this.handleChange('value')}
                    >
                        <FormControlLabel
                            value="default"
                            control={<Radio color="primary" />}
                            label="Default setting"
                            labelPlacement="end"
                            onClick={() => this.setState({
                                customInterval: this.state.defaultInterval,
                                unlimitedAccess: false
                            })}
                        />
                        <FormControlLabel
                            value="custom"
                            control={<Radio color="primary" />}
                            label="Custom setting"
                            labelPlacement="end"
                            onClick={() => this.setState({
                                customInterval: 0,
                                unlimitedAccess: false
                            })}
                        />
                        <TextField
                            className={classes.customeAction}
                            value={
                                this.state.value === 'custom' ? 
                                    this.state.customInterval <= 0  ? '' : this.state.customInterval : ''}
                            onChange={this.handleChange('customInterval')}
                        />
                        <FormControlLabel
                            value="never"
                            control={<Radio color="primary" />}
                            label="Never"
                            labelPlacement="end"
                            onClick={() => this.setState({
                                customInterval: -1,
                                unlimitedAccess: true
                            })}
                        />
                    </RadioGroup>
                </FormControl>
                <TextInput 
                    source={this.props.source} 
                    defaultValue={this.state.customInterval}
                    style={{ display: 'none' }} 
                    />
                <TextInput 
                    source='unlimitedAccess' 
                    defaultValue={this.state.unlimitedAccess}
                    style={{ display: 'none' }} 
                    />
            </div>
        );
    }
}

DeactivateAccount.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeactivateAccount);