import React, { Component } from 'react';
import { List, Datagrid, TextField, ImageField } from 'react-admin';

export class ImagingCoilList extends Component {  

  render() {
    const FileField = ({ record, ...rest }) => {
      return  <ImageField source="path" title="Image" record={record} {...rest} />
    };

    return (
      <List 
        {...this.props} 
        exporter={false}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="name" />
          <FileField/>
        </Datagrid>
      </List>
    );
  }
}