import React, { Component } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  DisabledInput,
  SelectArrayInput,
  NumberInput,
  SelectInput,
  BooleanInput,
  showNotification
} from 'react-admin';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { config } from '../../configs/config';
import CustomToolbar from '../customToolbar';
import { Button, Grid } from '@material-ui/core';
import { Api } from '../../services';
import Coils from './coilsModal';
import { inputsMRI } from './utils/utils-mri';
import { inputsCT } from './utils/utils-ct';
import './style.css';

const SELECT_ALL_ID = "12";
const SELECT_ALL = 'all';

const App = props => {

  if (props.record.source) {
    return <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{
        color: 'rgba(0, 0, 0, 0.54)',
        padding: 0,
        fontSize: '0.8rem',
        marginTop: '10px',
      }}>{props.label}</span>
      <span style={{
        marginTop: '10px',
      }}>{props.record.usedInApps.join(' ')}</span>
    </div>
  }
  return <SelectArrayInput
    {...props}
    label="Allowed in Apps*"
    source="usedInApps"
    choices={config.allowedApps}
    style={{ display: 'none' }}
    format={value => Array.isArray(value) ? value : []}
    defaultValue={props.usedInAppsState}
  />
}

class SequenceEdit extends Component {

  state = {
    files: [],
    sequenceId: 0,
    seriesDesc: '',
    protocol: [],
    usedInApps: [],
    fieldsArray: inputsMRI,
    protocolsArray: [],
    role: 0,
    isSharable: false,
    protocolData: {}
  }

  validateCreation = values => {
    const errors = {};
    this.setState({ protocol: values.protocolId }, () => this.handleSelect(this.state.protocol))
    if (!values.name) {
      errors.name = ['The Name is required'];
    }
    if (!values.serialNumber) {
      errors.serialNumber = ['The Serial Number is required'];
    }
    if (!values.imagingMode) {
      errors.imagingMode = ['The Imaging Mode is required'];
    }
    if (!values.imagingCoil) {
      errors.imagingCoil = ['The Imaging Coil is required'];
    }
    if (!values.usedInApps) {
      errors.usedInApps = ['The Used In Apps is required'];
    }
    if (!values.protocolId) {
      errors.protocolId = ['The Protocol is required'];
    }
    if (!values.type) {
      errors.type = ['The Type is required'];
    }
    if (!values.plane || (parseInt(values.plane) > 4 && parseInt(values.plane) < 1)) {
      values.plane = 3;
    }

    if (values.imgOptions
      && values.imgOptions.length === config.imgOptions.length) {
      values.imgOptions = [];
    }

    if (values.imgOptions && values.imgOptions.includes(SELECT_ALL_ID)) {
      values.imgOptions = config.imgOptions.filter(it => it.value !== SELECT_ALL).map(it => it.id)
    }

    if (this.state.sequenceId === 0) {
      this.setState({
        sequenceId: values.id
      })
    }

    if (values.seriesDesc !== !this.state.seriesDesc) {
      this.setState({
        seriesDesc: values.seriesDesc
      })
    }

    this.setState({
      isSharable: values.isSharable ? true : false,
      protocolData: values.protocol
    })

    return errors
  };

  componentDidMount = async () => {
    const resp = await Api.protocols.getAll();
    if (resp.status === 200)
      
      this.setState({ protocolsArray: resp.data }, () => this.handleSelect(this.state.protocol))
    else
      this.props.showNotification('Errors while getting data')

    this.setState({
      role: parseInt(localStorage.getItem('authRole')),
      authCustomerId: parseInt(localStorage.getItem('authCustomerId'))
    })
  }

  handleSelect = (id) => {
    let array = this.state.protocolsArray;
    let element = [];
    if (array)
      element = array.find(el => el.id === id);

    if (typeof element !== "undefined" && element.usedInApps.includes('ct') && this.state.fieldsArray !== inputsCT) {
      this.setState({ fieldsArray: inputsCT, usedInApps: element.usedInApps })
    } else if (typeof element !== "undefined" && element.usedInApps.includes('mri') && this.state.fieldsArray !== inputsMRI) {
      this.setState({ fieldsArray: inputsMRI, usedInApps: element.usedInApps })
    }
  }

  renderInputs = (element, k) => {
    if (element.inputType === 'text') {
      return (
        <TextInput
          key={`input-${k}`}
          source={element.field}
          required={element.required}
          label={element.label}
          defaultValue={element.defaultValue}
        />
      )
    }
    if (element.inputType === 'select') {
      return (
        <SelectInput
          key={`input-${k}`}
          source={element.field}
          required={element.required}
          label={element.label}
          choices={element.choices}
          defaultValue={element.defaultValue}
        />
      )
    }
    if (element.inputType === 'number') {
      if(element.field === 'fieldOfView' && this.state.usedInApps[0] === "ct"){
        const validateFOV = (value) => {
            if (!value) {
                return 'FoV is required';
            }
            if (value < 24 || value > 70) {
                return 'Must be between 24 and 70';
            }
            return undefined;
        };

        return (
          <NumberInput
            key={`input-${k}`}
            source={element.field}
            required={element.required}
            label={element.label}
            choices={element.choices}
            validate={validateFOV}
            inputProps={{
              step: element.step,
              min:element.min ? element.min : null,
              max:element.max ? element.max : null
            }}
          />
        )
      } else {
        return (
          <NumberInput
            key={`input-${k}`}
            source={element.field}
            required={element.required}
            label={element.label}
            choices={element.choices}
            onChange={()=>{}}
            inputProps={{
              step: element.step,
              min:element.min ? element.min : null,
              max:element.max ? element.max : null
            }}
          />
        )
      }
    }
    if (element.inputType === 'checkbox') {
      return (
        <BooleanInput
          key={`input-${k}`}
          source={element.field}
          required={element.required ? true : false}
          label={element.label}
        />
      )
    }
    if (element.inputType === 'selectArray') {
      return (
        <SelectArrayInput
          key={`input-${k}`}
          label={element.label}
          source={element.field}
          choices={element.choices}
          format={value => {
            return Array.isArray(value) ? value : []
          }}
          defaultValue={element.defaultValue}
        />
      )
    }
    if (element.inputType === 'coil') {
      return (
        <Coils key={`input-${k}`} source="imagingCoil" label="Coil *" />
      )
    }
  }

  share = async () => {
    const response = await Api.sequence.share(this.props.id);
    if (response.status === 200) {
      this.props.showNotification('Copy Created')
    }
  }

  render() {
    const array = this.state.fieldsArray;
    let toolbarProps = {}
    let skipProps = ['showNotification', 'endOfMessage']
    for (let k in this.props) {
      if (!skipProps.includes(k))
        toolbarProps = {
          ...toolbarProps,
          [k]: this.props[k]
        }
    }

    return (
      <div className="seq-edit">
        <Edit {...toolbarProps} >
          <SimpleForm
            validate={this.validateCreation}
            toolbar={<CustomToolbar
              isShare={this.state.role === config.superAdminId && this.state.isSharable}
              share={this.share}
              showDelBtn={true}
              modal={true}
              justText={true}
              redirectPath="/sequence"
              history={this.props.history}
              endOfMessage="All attached images and sounds will be deleted. Do you wish to proceed?"
            />} >
            <DisabledInput source="id" />
            <ReferenceInput
              label="Protocol"
              source="protocolId"
              reference="protocol"
              perPage={1000}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            {array.map((el, k) => (
              this.renderInputs(el, k)
            ))}
            <App label="Allowed in Apps*" usedInAppsState={this.state.usedInApps} />
            {this.state.protocolData.isSharable && <BooleanInput source="isSharable" />}
          </SimpleForm>
        </Edit>
        <div className="image-block">
          <div className="image-block-paper">
            <Grid container>
              <Grid container item direction="row" className="actions">
                <Button
                  // variant="contained" 
                  className="add"
                  onClick={() => {
                    localStorage.setItem('seriesDesc', this.state.seriesDesc);
                    localStorage.setItem('sequenceId', this.state.sequenceId);
                    this.props.history.push(`/admin-files/images-list/${this.state.sequenceId}`)
                  }}
                >Image List</Button>
                <Button
                  // variant="contained"
                  color="primary"
                  className="show-hide"
                  onClick={() => {
                    localStorage.setItem('seriesDesc', this.state.seriesDesc);
                    localStorage.setItem('sequenceId', this.state.sequenceId);
                    this.props.history.push(`/admin-files/sounds-list/${this.state.sequenceId}`)
                  }
                  }
                >Sound List</Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

SequenceEdit.propTypes = {
  showNotification: PropTypes.func
};

export default connect(null, {
  showNotification,
})(SequenceEdit);
