import React from 'react';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
import UploadCSV from '../utils/uploadCSV';
import { Button } from '@material-ui/core';

import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';

import './style.css';

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  endpoint,
  history,
  sequenceId,
  goBack,
  backBtn,
  filetype,
  showUploadCSV,
  backPath
}) => (
    <CardActions>
      {bulkActions && React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
      {filters && React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {backBtn && <Button
        className="cancel-btn-action"
        onClick={() => {
          if (backPath) {
            history.push(backPath)
          } else {
            goBack()
          }
        }
        }
      >
        Back
      </Button>}
      {sequenceId ? <Button className="custom-create" onClick={() => history.push(`/admin-files/${filetype}/${sequenceId}/create`)}><span className="plus">+</span>Create</Button> :
        <CreateButton basePath={basePath} />}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      {/* Add your custom actions */}
      {showUploadCSV && <UploadCSV accept=".csv" endpoint={endpoint} />}
    </CardActions>
  );

export default connect(null, {
  goBack
})(PostActions);