import React, { Component } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  downloadCSV,
  Filter,
  TextInput,
  SelectInput
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import PostActions from '../customListActions/sequencesActions';
import { config } from '../../configs/config';
import { Api } from '../../services';
import { inputsCT } from './utils/utils-ct';
import { inputsMRI } from './utils/utils-mri';

class PostFilter extends React.PureComponent {

  state = {
    protocols: [],
    partId: 0,
  }

  componentDidMount() {
    let part = 0;
    if (this.props.filterValues.part)
      part = this.props.filterValues.part;

    this.getProtocols(part);
  }

  getProtocols = async (id) => {
    if (id && parseInt(id) !== 0) {
      const response = await Api.protocols.getByPartId(id);
      if (response.status === 200)
        this.setState({
          protocols: response.data
        })
    } else {
      const response = await Api.protocols.getAll();
      if (response.status === 200)
        this.setState({
          protocols: response.data
        })
    }

  }

  // <TextInput label="Search" source="name" alwaysOn />
  render() {
    return (
      <Filter {...this.props}>
        <SelectInput
          source="part"
          label="Body Part"
          choices={config.bodyParts}
          onChange={(e, v) => this.getProtocols(v)}
          alwaysOn
        />
        <SelectInput
          source="protocol"
          label="Protocols"
          choices={this.state.protocols ? this.state.protocols.sort((el1, el2) => el1.name.localeCompare(el2.name)) : []}
          alwaysOn
        />
      </Filter>
    )
  }
}

export class SequenceList extends Component {

  state = {
    apps: '',
    role: ''
  }

  componentDidMount() {
    const apps = localStorage.getItem('allowed');
    const role = localStorage.getItem('authRole');
    this.setState({
      apps,
      role
    })
  }

  prepareSequencesForExport = sequences => {
    return sequences.map(sequence => {
      delete sequence.files;
      delete sequence.protocol;
      delete sequence.name;

      sequence = {
        ...sequence,
        seriesName: sequence.seriesDesc,
        flowMlSec: sequence.sflowMlSecideB,
      };

      if (sequence.imgOptions)
        sequence.imgOptions = sequence.imgOptions.join('&');

      delete sequence.seriesDesc;
      delete sequence.sflowMlSecideB;

      const fields = sequence.usedInApps.includes(config.ct) ?
        inputsCT.map(el => el.field) : inputsMRI.map(el => el.field);
      fields.push('id', 'protocolId', 'seriesName');

      if (sequence.usedInApps.includes(config.ct))
        fields.push('flowMlSec');

      for (let k in sequence) {
        if (!fields.includes(k))
          delete sequence[k]
      }

      return sequence
    });
  }

  exporter = (sequences, type, _this) => {
    sequences = this.prepareSequencesForExport(sequences.filter(el => el.usedInApps.includes(type)));
    const csv = convertToCSV({
      data: sequences
    });

    _this.setState({ anchorEl: null });

    downloadCSV(csv, `sequences-${type}`);
  }

  render() {
    return (
      <React.Fragment>
        <List
          {...this.props}
          bulkActions={false}
          exporter={this.exporter}
          filters={<PostFilter />}
          actions={<PostActions
            {...this.props}
            endpointСT="sequence/csv/ct"
            endpointMRI="sequence/csv/mri"
            basePath="sequence"
            ct={this.state.apps.includes(config.ct) || this.state.role == config.superAdminId ? true : false}
            mri={this.state.apps.includes(config.mri) || this.state.role == config.superAdminId ? true : false}
          />}
        >
          <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField source="protocolId" reference="protocol" allowEmpty={true}>
              <TextField source="name" />
            </ReferenceField>
            <TextField source="serialNumber" />
            <TextField source="seriesDesc" label="Series Name" />
            <TextField source="type" />
            <TextField source="usedInApps" />
          </Datagrid>
        </List>
      </React.Fragment>
    );
  }
}