import { config } from '../../../configs/config';

export const inputsMRI = [
  {
    label: 'Series Name',
    field: 'seriesDesc',
    inputType: 'text',
    required: true,
    defaultValue: 'Axial T2 FSE'
  },
  {
    field: 'serialNumber',
    inputType: 'number',
    required: true,
    defaultValue: 5,
  },
  {
    field: 'type',
    inputType: 'select',
    choices: config.sequences.type,
    required: true,
  },
  // {
  //   field: 'seriesDesc',
  //   inputType: 'text',
  // },
  {
    label: 'Patient Position',
    field: 'patientPosition',
    inputType: 'select',
    choices: config.patientPositions,
    defaultValue: 1,
  },
  {
    label: 'Patient Entry',
    field: 'patientEntry',
    inputType: 'select',
    choices: config.patientEntries,
    defaultValue: 1
  },
  {
    label: 'Mode',
    field: 'imagingMode',
    inputType: 'text',
    required: true,
    defaultValue: '2D'
  },
  {
    field: 'imagingCoil',
    inputType: 'coil',
  },
  {
    field: 'plane', 
    inputType: 'select',
    choices: config.planeType,
    required: true,
    defaultValue: '3'
  },
  {
    field: 'pulseSequence',
    inputType: 'text',
    defaultValue: 'T2 Fast Spin Echo'
  },
  {
    label: 'TR',
    field: 'tr',
    inputType: 'text',
    defaultValue: 65,
  },
  {
    label: 'Min TR',
    field: 'minTr',
    inputType: 'text',
    defaultValue: 60
  },
  {
    label: 'Max TR',
    field: 'maxTr',
    inputType: 'text',
    defaultValue: 70
  },
  {
    label: 'TE',
    field: 'te',
    inputType: 'text',
    defaultValue: 100
  },
  {
    label: 'Min TE',
    field: 'minTe',
    inputType: 'text',
    defaultValue: 100
  },
  {
    label: 'Max TE',
    field: 'maxTe',
    inputType: 'text',
    defaultValue: 200
  },
  {
    label: 'TI',
    field: 'ti',
    inputType: 'text',
    defaultValue: 2200
  },
  {
    label: 'Min TI',
    field: 'minTi',
    inputType: 'text',
    defaultValue: 1000
  },
  {
    label: 'Max TI',
    field: 'maxTi',
    inputType: 'text',
    defaultValue: 3000
  },
  {
    label: 'TE2',
    field: 'tez',
    inputType: 'text',
  },
  {
    label: 'Min TE2',
    field: 'minTez',
    inputType: 'text',
  },
  {
    label: 'Max TE2',
    field: 'maxTez',
    inputType: 'text',
  },
  {
    label: 'Flip Angle',
    field: 'flipAngle',
    inputType: 'text',
    defaultValue: 90
  },
  {
    label: 'Min Flip Angle',
    field: 'minFlipAngle',
    inputType: 'text',
    defaultValue: 0
  },
  {
    label: 'Max Flip Angle',
    field: 'maxFlipAngle',
    inputType: 'text',
    defaultValue: 360
  },
  {
    label: 'Echo Train Length',
    field: 'echoTrainFactor',
    inputType: 'text',
  },
  {
    label: 'Min Echo Train Length',
    field: 'minEchoTrainFactor',
    inputType: 'text',
  },
  {
    label: 'Max Echo Train Length',
    field: 'maxEchoTrainFactor',
    inputType: 'text',
  },
  {
    field: 'bandwidth',
    inputType: 'text',
    defaultValue: 0
  },
  {
    field: 'minBandwidth',
    inputType: 'text',
    defaultValue: 0
  },
  {
    field: 'maxBandwidth',
    inputType: 'text',
    defaultValue: 10
  },
  {
    field: 'fov',
    inputType: 'text',
    defaultValue: 24
  },
  {
    label: 'Min FOV',
    field: 'minFov',
    inputType: 'text',
    defaultValue: 0
  },
  {
    label: 'Max FOV',
    field: 'maxFov',
    inputType: 'text',
    defaultValue: 40
  },
  {
    field: 'sliceThickness',
    inputType: 'text',
    defaultValue: 5
  },
  {
    field: 'sliceSpacing',
    inputType: 'text',
    defaultValue: 1
  },
  {
    field: 'numberSlices',
    inputType: 'text',
    defaultValue: 25
  },
  {
    field: 'startLocation',
    inputType: 'text',
    defaultValue: 'I80'
  },
  {
    field: 'endLocation',
    inputType: 'text',
    defaultValue: 'S60'
  },
  {
    field: 'contrastAmount',
    inputType: 'text',
    defaultValue: '0 cc'
  },
  {
    field: 'contrastAgent',
    inputType: 'text',
    defaultValue: 'None'
  },
  {
    field: 'frequencyMatrix',
    inputType: 'text',
    defaultValue: 256,
  },
  {
    field: 'phaseMatrix',
    inputType: 'text',
    defaultValue: 128,
  },
  {
    label: 'NEX',
    field: 'nex',
    inputType: 'text',
    defaultValue: 1
  },
  {
    field: 'phaseDirection',
    inputType: 'text',
    defaultValue: 'L/R'
  },
  {
    field: 'frequencyDirection',
    inputType: 'text',
    defaultValue: 'A/P'
  },
  {
    field: 'reductionFactor',
    inputType: 'text',
    defaultValue: 1
  },
  {
    label: 'Imaging Options',
    field: 'imgOptions',
    inputType: 'selectArray',
    choices: config.imgOptions,
    defaultValue: ['1', '9'],
  },
]