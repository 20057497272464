import React, { Component } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  downloadCSV,
  BooleanField,
  Filter,
  SelectInput
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import PostActions from '../customListActions';
import { config } from '../../configs/config';
import DeactivateSettings from './components/setDefaultSettings';
import { Api } from '../../services';

class PostFilter extends React.PureComponent {

  state = {
    customers: []
  }

  async componentDidMount() {
    const response = await Api.customers.getAll();
    if (response.status === 200)
      this.setState({
        customers: response.data.map(el => ({
          id: el.id,
          name: el.name,
          value: el.id
        }))
      })
  }

  render() {
    return (
      <Filter {...this.props}>
        <SelectInput
          source="customerId"
          label="Customer"
          choices={this.state.customers}
          alwaysOn
        />
      </Filter>
    );
  }
}

export class UserList extends Component {

  state = {
    role: 0,
  }

  componentDidMount() {
    this.setState({
      role: parseInt(localStorage.getItem('authRole')),
    })
  }

  exporter = users => {
    users.map(user => {
      delete user.role;
      delete user.customer;
      delete user.logs;

      if (this.state.role !== config.superAdminId)
        delete user.customerId;

      if (!user.unlimitedAccess) {
        user.unlimitedAccess = 0;
      }
      let appCode = '';
      user.appCode.forEach(el => {
        appCode += `${el}&`
      })
      appCode = appCode.slice(0, -1);
      user.appCode = appCode;

      return user;
    })
    const csv = convertToCSV({
      data: users
    });

    downloadCSV(csv, 'users');
  }

  render() {
    return (
      <React.Fragment>
        {this.state.role === config.superAdminId && <DeactivateSettings />}
        <List
          {...this.props}
          exporter={this.exporter}
          className="user-list"
          filters={(this.state.role === config.superAdminId) ? <PostFilter /> : null}
          actions={<PostActions showUploadCSV endpoint="user" />}
        >
          <Datagrid rowClick="edit" className="user-list-table">
            <TextField source="id" />
            {
              this.state.role === config.superAdminId ?
                <ReferenceField source="customerId" reference="customer" label="Organization">
                  <TextField source="name" />
                </ReferenceField>
                : null
            }
            <TextField source="username" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <TextField source="email" />
            <TextField source="city" />
            <TextField source="state" />
            <BooleanField source="separateSeat" />
            <TextField source="deactivateInterval" />
            <BooleanField source="unlimitedAccess" />
            <BooleanField source="active" />
            <TextField source="appCode" />
          </Datagrid>
        </List>
      </React.Fragment>
    );
  }
}