import React, { Component } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  NumberInput,
  BooleanInput
} from 'react-admin';
import { config } from '../../configs/config';
import CustomToolbar from '../customToolbar';
import { Api } from '../../services';
import { inputsMRI } from './utils/utils-mri';
import { inputsCT } from './utils/utils-ct';
import Coils from './coilsModal';

const SELECT_ALL_ID = "12";
const SELECT_ALL = 'all';

export class SequenceCreate extends Component {

  state = {
    usedInApps: [],
    fieldsArray: [],
    protocolsArray: [],
    isProtocolSharable: 0
  }

  validateCreation = values => {
    const errors = {};
    if (!values.name) {
      errors.name = ['The Name is required'];
    }
    if (!values.serialNumber) {
      errors.serialNumber = ['The Serial Number is required'];
    }
    if (!values.imagingMode) {
      errors.imagingMode = ['The Imaging Mode is required'];
    }
    if (!values.usedInApps) {
      errors.usedInApps = ['The Used In Apps is required'];
    }
    if (!values.protocolId) {
      errors.protocolId = ['The Protocol is required'];
    }
    if (!values.type) {
      errors.type = ['The Type is required'];
    }
    if (!values.plane || (parseInt(values.plane) > 4 && parseInt(values.plane) < 1)) {
      values.plane = 3;
    }

    if (values.imgOptions
      && values.imgOptions.length === config.imgOptions.length) {
      values.imgOptions = [];
    }
    if (values.imgOptions && values.imgOptions.includes(SELECT_ALL_ID)) {
      values.imgOptions = config.imgOptions.filter(it => it.value !== SELECT_ALL).map(it => it.id)
    }

    if (values.imgOptions && values.imgOptions.includes(12)) {
      let imgOptions = [];
      config.imgOptions.forEach(el => {
        if (el.value !== 'all')
          imgOptions.push(el.id)
      })
      values.imgOptions = imgOptions;
    }

    return errors
  }

  renderInputs = element => {
    if (element.inputType === 'text') {
      return (
        <TextInput
          source={element.field}
          required={element.required ? true : false}
          label={element.label}
          defaultValue={element.defaultValue}
        />
      )
    }
    if (element.inputType === 'select') {
      return (
        <SelectInput
          source={element.field}
          required={element.required ? true : false}
          label={element.label}
          choices={element.choices}
          defaultValue={element.defaultValue}
          style={{ display: element.hidden ? 'none' : 'block'}}
        />
      )
    }
    if (element.inputType === 'number') {
      return (
        <NumberInput
          source={element.field}
          required={element.required ? true : false}
          label={element.label}
          choices={element.choices}
          step={element.step}
          min={element.min ? element.min : null}
          max={element.max ? element.max : null}
          defaultValue={element.defaultValue}
        />
      )
    }
    if (element.inputType === 'checkbox') {
      return (
        <BooleanInput
          source={element.field}
          required={element.required ? true : false}
          label={element.label}
        />
      )
    }
    if (element.inputType === 'selectArray') {
      return (
        <SelectArrayInput
          label={element.label}
          source={element.field}
          choices={element.choices}
          defaultValue={element.defaultValue}
        />
      )
    }
    if (element.inputType === 'coil') {
      return (
        <Coils source="imagingCoil" label="Coil *" />
      )
    }
  }

  componentDidMount = async () => {
    const resp = await Api.protocols.getAll();
    this.setState({ protocolsArray: resp.data })
  }

  handleSelect = async (id) => {
    let array = this.state.protocolsArray
    let element = [];
    if (array) {
      element = array.find(el => el.id === id);
      if (element)
        this.setState({
          isProtocolSharable: element.isSharable
        })
    }
    if (element !== undefined && element.usedInApps && element.usedInApps.includes('ct') && this.state.fieldsArray !== inputsCT) {
      this.setState({ fieldsArray: inputsCT, usedInApps: element.usedInApps })
      return;
    } else if (element !== undefined && element.usedInApps && element.usedInApps.includes('mri') && this.state.fieldsArray !== inputsMRI) {
      this.setState({ fieldsArray: inputsMRI, usedInApps: element.usedInApps })
    }
  }

  render() {
    const array = this.state.fieldsArray;
    return (
      <Create {...this.props} >
        <SimpleForm validate={this.validateCreation} redirect={`/sequence`} toolbar={<CustomToolbar />} >
          <ReferenceInput
            label="Protocol"
            source="protocolId"
            reference="protocol"
            perPage={10000}
            sort={{ field: 'name', order: 'ASC' }}
            required
            onChange={(e, v) => this.handleSelect(v)}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          {array.map(el => (
            this.renderInputs(el)
          ))}
          <SelectArrayInput
            label="Allowed in Apps*"
            source="usedInApps"
            choices={config.allowedApps}
            style={{ display: 'none' }}
            defaultValue={this.state.usedInApps}
          />
          {this.state.isProtocolSharable && <BooleanInput source="isSharable" />}
        </SimpleForm>
      </Create>
    );
  }
}